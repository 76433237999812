import ShortenNumber from "../utilities/ShortenNumber";
import rightWhiteArrow from '../assets/images/icons/right-white-arrow.png';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import ChangingProgressProvider from "./ChangingProgressProvider";
import 'react-circular-progressbar/dist/styles.css';
import Spinner from "react-bootstrap/Spinner";

export default function LongVsShortVideos({ profiledata, setVideoChartTab, setChartTabs, loadingVideos }) {
    const [shortVideos, setShortvideos] = useState([])
    const [longVideos, setLongVideos] = useState([])
    const [shortPercentage, setShortPercentage] = useState(0)
    const [shortViews, setShortViews] = useState(0)
    const [longViews, setLongViews] = useState(0)
    const [daysFilter, setDaysFilter] = useState(30)
    const videosData = useSelector((state) => state?.reels?.reelsData);
    const [longPercentage, setLongPercentage] = useState(0)
    const [loadingData, setLoadingData]  = useState(true)
    // useEffect(() => {
    //     const totalVideos = videosData || [];
    //     const shortVideos = []
    //     const longVideos = []
    //     var shortViews = 0
    //     var longViews = 0

    //     totalVideos?.forEach((item) => {
    //         let duration = profiledata?.platform == 'instagram' ? item?.Duration : item.duration
    //         if (duration > 30) {
    //             longVideos.push(item)
    //             longViews += profiledata?.platform == 'instagram' ? item?.Views : item.views
    //         } else {
    //             shortVideos.push(item)
    //             shortViews += profiledata?.platform == 'instagram' ? item?.Views : item.views
    //         }
    //     })

    //     setShortvideos(shortVideos)
    //     setLongVideos(longVideos)
    //     setShortViews(shortViews)
    //     setLongViews(longViews)
    //     const shortVideosPercentage = (shortVideos.length / totalVideos.length) * 100

    //     const longVideosPercentage = (longVideos.length / totalVideos.length) * 100
    //     setShortPercentage(Math.ceil(shortVideosPercentage || 0))


    // }, [videosData])
    // useEffect(() => {
    //     setLoadingData(true)
    //     if (!videosData) return;
    //     const uniqueDates = [...new Set(videosData?.map(item => new Date(item['Date Posted'] || item.date).toDateString()))];

    //     if (uniqueDates.length < 30 && loadingVideos) {
    //         console.log('Not enough data for 30 days');
    //         return;
    //     }
    //     setLoadingData(false)
    //     const currentDate = new Date();
    //     const filterDate = new Date(currentDate.setDate(currentDate.getDate() - daysFilter));

    //     const filteredVideos = daysFilter ? videosData.filter((item) => {
    //         const videoDate = new Date(item['Date Posted'] || item.date);
    //         return videoDate >= filterDate;
    //     }) : videosData;

    //     const shortVideos = [];
    //     const longVideos = [];
    //     let shortViews = 0;
    //     let longViews = 0;

    //     filteredVideos.forEach((item) => {
    //         let duration = profiledata?.platform === 'instagram' ? item?.Duration : item.duration;
    //         if (duration > 30) {
    //             longVideos.push(item);
    //             longViews += profiledata?.platform === 'instagram' ? item?.Views : item.views;
    //         } else {
    //             shortVideos.push(item);
    //             shortViews += profiledata?.platform === 'instagram' ? item?.Views : item.views;
    //         }
    //     });

    //     setShortvideos(shortVideos);
    //     setLongVideos(longVideos);
    //     setShortViews(shortViews);
    //     setLongViews(longViews);

    //     const shortVideosPercentage = (shortVideos.length / filteredVideos.length) * 100;
    //     const longVideosPercentage = (longVideos.length / filteredVideos.length) * 100;

    //     setShortPercentage(Math.ceil(shortVideosPercentage || 0));
    //     setLongPercentage(Math.ceil(longVideosPercentage || 0));

    // }, [videosData, daysFilter, profiledata]);

    useEffect(() => {
        let isMounted = true; // Track if the component is mounted
    
        const fetchData = async () => {
            if (!videosData) return;
    
            const uniqueDates = [...new Set(videosData?.map(item => new Date(item['Date Posted'] || item.date).toDateString()))];
    
            if (uniqueDates.length < 30 && loadingVideos) {
                console.log('Not enough data for 30 days');
                // if (isMounted) setLoadingData(false); // Only set loading state if component is still mounted
                return;
            }
    
            const currentDate = new Date();
            const filterDate = new Date(currentDate.setDate(currentDate.getDate() - daysFilter));
    
            const filteredVideos = daysFilter ? videosData.filter((item) => {
                const videoDate = new Date(item['Date Posted'] || item.date);
                return videoDate >= filterDate;
            }) : videosData;
    
            const shortVideos = [];
            const longVideos = [];
            let shortViews = 0;
            let longViews = 0;
    
            filteredVideos.forEach((item) => {
                let duration = profiledata?.platform === 'instagram' ? item?.Duration : item.duration;
                if (duration > 30) {
                    longVideos.push(item);
                    longViews += profiledata?.platform === 'instagram' ? item?.Views : item.views;
                } else {
                    shortVideos.push(item);
                    shortViews += profiledata?.platform === 'instagram' ? item?.Views : item.views;
                }
            });
    
            if (isMounted) {
                setShortvideos(shortVideos);
                setLongVideos(longVideos);
                setShortViews(shortViews);
                setLongViews(longViews);
    
                const shortVideosPercentage = (shortVideos.length / filteredVideos.length) * 100;
                const longVideosPercentage = (longVideos.length / filteredVideos.length) * 100;
    
                setShortPercentage(Math.ceil(shortVideosPercentage || 0));
                setLongPercentage(Math.ceil(longVideosPercentage || 0));
                setLoadingData(false); // Only set loading state if component is still mounted
            }
        };
    
        setLoadingData(true); // Set loading state to true initially
        fetchData(); // Fetch and process data
    
        return () => {
            isMounted = false; // Cleanup function to set isMounted to false
        };
    }, [videosData, daysFilter, profiledata, loadingVideos]);
    

    return (
        <div className="long-vs-short-videos">
            <div className="content-wrapper">
                <div className="title  mb-3">

                    <div className="d-flex gap-4 align-items-center">
                        <span className="white strong">Long VS Short Videos</span>
                    </div>

                    {loadingData ? <span
                        className='white d-inline-flex gap-2'>  <Spinner
                            animation="grow" size="sm" /><span
                                className=' d-inline-block white'>Analyzing...</span></span> : <ul className="result">
                        <li className={`${daysFilter == 7 ? 'active' : ''}`}
                            onClick={() => setDaysFilter(7)}>7D
                        </li>
                        <li className={`${daysFilter == 30 ? 'active' : ''}`}
                            onClick={() => setDaysFilter(30)}>30D
                        </li>
                        {/* <li className={`${daysFilter == null ? 'active' : ''}`}
                            onClick={() => setDaysFilter(null)}>All
                        </li> */}
                    </ul>}
                </div>
                <div
                    className="progress-circular-bar-wrapper">

                    <div className="row">
                        <div  className="col-xl-7 col-lg-7 col-md-7 align-self-center">
                            <div
                                className="progress-bar-wrapper">
                                {/*  <CircularProgressbar
                                    value={shortPercentage}
                                    text={`${shortPercentage}%`}
                                    counterClockwise={true} />*/}
                                <ChangingProgressProvider values={[shortPercentage]}>
                                    {value => (
                                        <CircularProgressbarWithChildren
                                            value={shortPercentage}
                                            circleRatio={0.75}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                trailColor: '#FF165D',
                                                pathColor: '#00E0FF', // Adjust the color to match the image
                                                pathTransitionDuration: 0.5,
                                                strokeDasharray: '1, 4', // Adjusted pattern for visible dots
                                                path: {
                                                    strokeDasharray: '1, 5', // Create the dot pattern
                                                },
                                                trail: {
                                                    strokeLinecap: 'butt',
                                                },
                                            })}
                                        >
                                            <div className='text-wrapper'>
                                                <span className='white'>{shortPercentage > longPercentage ? 'Short' : 'Long'} Views</span>
                                                <h2 className='white'>{`${shortPercentage > longPercentage ? shortPercentage : longPercentage}%`}</h2>
                                            </div>
                                        </CircularProgressbarWithChildren>
                                    )}
                                </ChangingProgressProvider>

                            </div>
                        </div>
                        <div  className="col-xl-5 col-lg-5 col-md-5 align-self-center">
                            <div
                                className="views-wrapper">
                                <div
                                    className="content">
                                    <span className='white small mb-1'>Short Views</span>
                                    <div className="text">
                                        <span style={{ display: 'inline-block' }}>Views:  </span>
                                        <strong>{ShortenNumber(shortViews)} </strong>
                                    </div>
                                    <div className="text">
                                        <span style={{ display: 'inline-block' }}>Videos: </span>
                                        <strong>  {shortVideos?.length || 0} </strong>
                                    </div>
                                    <div className="text">
                                        <span style={{ display: 'inline-block' }}>Percentage: </span>
                                        <strong> {shortPercentage}%</strong>
                                    </div>
                                </div>
                                <div
                                    className="content">
                                    <span className='white small mb-1'>Long Views</span>
                                    <div className="text">
                                        <span style={{ display: 'inline-block' }}>Views:  </span>
                                        <strong>{ShortenNumber(longViews)} </strong>
                                    </div>
                                    <div className="text">
                                        <span style={{ display: 'inline-block' }}>Videos: </span>
                                        <strong> {longVideos?.length || 0} </strong>
                                    </div>
                                    <div className="text">
                                        <span style={{ display: 'inline-block' }}>Percentage: </span>
                                        <strong> {longPercentage}%</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a onClick={() => {
                setVideoChartTab('charts');
                setChartTabs('characteristics');
                window.scrollTo({
                    top: 700,
                    left: 0,
                    behavior: 'smooth'
                });
            }}
                className="btn-style simple dark view-full-btn">View
                Full Data <img src={rightWhiteArrow}
                    alt="rightWhiteArrow" /></a>
        </div>
    )
}