// import React, { useEffect, useRef, useState } from 'react';
// import Chart from 'chart.js/auto'; // Import Chart object

// export default function LineChartLikesPerWeekVideos({ analyticsData }) {
//     const canvasRef = useRef(null);
//     const [perWeekVideosSeries, setPerWeekVideosSeries] = useState([]);

//     useEffect(() => {
//         if (!analyticsData) return;

//         const likesTimeSeries = analyticsData?.videos_timeseries || [];

//         // Helper function to get the week number of the year
//         function getWeekNumber(d) {
//             const date = new Date(d);
//             date.setHours(0, 0, 0, 0);
//             date.setDate(date.getDate() + 4 - (date.getDay() || 7));
//             const yearStart = new Date(date.getFullYear(), 0, 1);
//             const weekNo = Math.ceil((((date - yearStart) / 86400000) + 1) / 7);
//             return weekNo;
//         }

//         // Calculate the date three months ago
//         const mostRecentDate = new Date(Math.max(...likesTimeSeries.map(videos => new Date(videos['Date Posted']))));
//         const threeMonthsAgo = new Date(mostRecentDate.setMonth(mostRecentDate.getMonth() - 3));

//         // Filter likesTimeSeries to include only the last 3 months
//         const filteredTimeSeries = likesTimeSeries?.filter(videos => new Date(videos['Date Posted']) >= threeMonthsAgo);

//         // Reduce the filtered data to weekly counts
//         const perWeekVideosSeries = filteredTimeSeries?.reduce((acc, videos) => {
//             const date = new Date(videos['Date Posted']);
//             const week = getWeekNumber(date);
//             const year = date.getFullYear();
//             const weekYear = `${year}-W${week}`;

//             if (!acc[weekYear]) {
//                 acc[weekYear] = {
//                     week: weekYear,
//                     startDate: new Date(date.setDate(date.getDate() - date.getDay() + 1)).toLocaleDateString(), // Start date of the week
//                     count: 0
//                 };
//             }
//             acc[weekYear].count += 1;
//             return acc;
//         }, {});

//         const processedPerWeekVideosSeries = Object.values(perWeekVideosSeries);
//         console.log('processedPerWeekVideosSeries', processedPerWeekVideosSeries);

//         setPerWeekVideosSeries(processedPerWeekVideosSeries);
//     }, [analyticsData]);

//     useEffect(() => {
//         // Create gradient
//         const canvas = canvasRef.current;
//         const ctx = canvas.getContext('2d');
//         const gradient = ctx.createLinearGradient(0, 0, 0, 400); // Adjust according to your chart size
//         gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
//         gradient.addColorStop(0, '#0DABEA66'); // Adjust as needed
//         // rgba(92, 74, 133,1)

//         // Apply gradient as background color
//         const chartInstance = new Chart(ctx, {
//             type: 'line',
//             data: {
//                 labels: perWeekVideosSeries?.map((share) => share['startDate']),
//                 datasets: [
//                     {
//                         label: 'Dataset',
//                         backgroundColor: gradient,
//                         borderColor: '#0DABEA',
//                         borderWidth: 2,
//                         fill: true,
//                         pointBackgroundColor: 'rgba(0, 0, 0, 0)',
//                         pointBorderColor: 'rgba(0, 0, 0, 0)',
//                         pointHoverBackgroundColor: '#fff',
//                         pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
//                         data: perWeekVideosSeries?.map((like) => (like?.count?.toFixed(0))),
//                         lineTension: 0.4, // Adjust the tension value for smoothness
//                         zIndex: 1,
//                     }
//                 ]
//             },
//             options: {
//                 plugins: {
//                     legend: {
//                         display: false,
//                     }
//                 },
//                 scales: {
//                     x: {
//                         ticks: {
//                             color:'#D9D9D9',
//                         },
//                         grid: {
//                             display: false,
//                             z: 99
//                         }
//                     },
//                     y: {
//                         ticks: {
//                             color:'#D9D9D9',
//                             beginAtZero: true,
//                             maxTicksLimit: 5
//                         },
//                         grid: {
//                             display: true,
//                             color: 'rgba(100, 111, 124, .2)',
//                             zIndex: 9999,
//                             drawOnChartArea: true,
//                         },
//                     }
//                 },
//             },
//         });

//         return () => {
//             chartInstance.destroy(); // Cleanup chart instance when component unmounts
//         };
//     }, [perWeekVideosSeries]);

//     return (
//         <div className='graph-chart-wrapper'>
//             <canvas ref={canvasRef} />
//         </div>
//     );
// }






import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto'; // Import Chart object
import moment from 'moment';

export default function LineChartLikesPerWeekVideos({ analyticsData }) {
    const canvasRef = useRef(null);
    const [perWeekVideosSeries, setPerWeekVideosSeries] = useState([]);

    useEffect(() => {
        if (!analyticsData) return;

        const likesTimeSeries = analyticsData?.videos_timeseries || [];

        // Helper function to get the start date of the week (Monday)
        function getWeekStartDate(d) {
            const date = new Date(d);
            const day = date.getDay();
            const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
            return new Date(date.setDate(diff));
        }

        // Calculate the date three months ago
        const mostRecentDate = new Date(Math.max(...likesTimeSeries.map(videos => new Date(videos['Date Posted']))));
        const threeMonthsAgo = new Date(mostRecentDate.setMonth(mostRecentDate.getMonth() - 3));

        // Filter likesTimeSeries to include only the last 3 months
        const filteredTimeSeries = likesTimeSeries?.filter(videos => new Date(videos['Date Posted']) >= threeMonthsAgo);
        console.log('Filtered Time Series:', filteredTimeSeries); // Debugging log

        // Reduce the filtered data to weekly counts
        const perWeekVideosSeries = filteredTimeSeries?.reduce((acc, videos) => {
            const date = new Date(videos['Date Posted']);
            const startDate = moment(getWeekStartDate(date)).format('MM/DD/YY');
            if (!acc[startDate]) {
                acc[startDate] = {
                    startDate,
                    count: 0
                };
            }
            acc[startDate].count += videos.Videos;
            return acc;
        }, {});

        console.log('Per Week Videos Series:', perWeekVideosSeries); // Debugging log

        const processedPerWeekVideosSeries = Object.values(perWeekVideosSeries);
        console.log('Processed Per Week Videos Series:', processedPerWeekVideosSeries); // Debugging log

        setPerWeekVideosSeries(processedPerWeekVideosSeries);
    }, [analyticsData]);

    useEffect(() => {
        // Create gradient
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 400); // Adjust according to your chart size
        gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
        gradient.addColorStop(0, '#0DABEA66'); // Adjust as needed

        // Apply gradient as background color
        const chartInstance = new Chart(ctx, {
            type: 'line',
            data: {
                labels: perWeekVideosSeries?.map((share) => share['startDate']),
                datasets: [
                    {
                        label: 'Dataset',
                        backgroundColor: gradient,
                        borderColor: '#0DABEA',
                        borderWidth: 2,
                        fill: true,
                        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                        pointBorderColor: 'rgba(0, 0, 0, 0)',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
                        data: perWeekVideosSeries?.map((like) => (like?.count?.toFixed(0))),
                        lineTension: 0.4, // Adjust the tension value for smoothness
                        zIndex: 1,
                    }
                ]
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#D9D9D9',
                        },
                        grid: {
                            display: false,
                            z: 99
                        }
                    },
                    y: {
                        ticks: {
                            color: '#D9D9D9',
                            beginAtZero: true,
                            maxTicksLimit: 5
                        },
                        grid: {
                            display: true,
                            color: 'rgba(100, 111, 124, .2)',
                            zIndex: 9999,
                            drawOnChartArea: true,
                        },
                    }
                },
            },
        });

        return () => {
            chartInstance.destroy(); // Cleanup chart instance when component unmounts
        };
    }, [perWeekVideosSeries]);

    return (
        <div className='graph-chart-wrapper'>
            <canvas ref={canvasRef} />
        </div>
    );
}
