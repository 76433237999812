// import React, { useEffect, useState } from 'react'

// // **************************** Images *****************************

// import hamburgerIcon from "../assets/images/icons/hamburger-icon.png";
// import bellIcon from "../assets/images/icons/bell-icon.png";
// import halfIconIcon from "../assets/images/icons/half-moon-ico.png";
// import sunIcon from "../assets/images/icons/sun-icon.png";
// import thundarIcon from "../assets/images/icons/thundar-icon.png";
// import NotificationsComponent from "./NotificationsComponent";
// import socketIOClient from "socket.io-client";
// import ProgressBar from 'react-bootstrap/ProgressBar'
// import Select from "react-select";
// import generateIcon from "../assets/images/icons/generate-icon.png";
// import { useNavigate } from 'react-router-dom';

// export const socket = socketIOClient(`${process.env.REACT_APP_ASSET_PATH}`);

// export default function HeaderComponent({ sidebarActiveHandler, sidebarMobileActiveHandler, handleChange, options, selectedOption, usernameInput, setUsernameInput, handleSearch, showSearch, submittingUsernameLoading }) {

//     const [notificationActive, setNotificationActive] = useState(false);
//     const navigate = useNavigate()
//     const user = () => {
//         try {
//             let user = localStorage.getItem("user");
//             if (user) user = JSON.parse(user);
//             return user ?? null;
//         } catch (error) {
//             return null;
//         }
//     };

//     const userData = user()

//     const notificationHandler = () => {
//         setNotificationActive(!notificationActive);
//         socket.emit("check_all_notifications");
//     };
//     const [feeds, setFeeds] = useState([]);
//     const [isNewFeed, setIsNewFeed] = useState(false);
//     useEffect(() => {
//         socket.emit("initial_notifications_data", userData?.id || null);
//         socket.on("get_data", getData);
//         socket.on("change_notification_data", changeData);
//         return () => {
//             socket.off("get_data");
//             socket.off("change_notification_data");
//         };
//     }, []);

//     const getData = (feeds) => {
//         if (feeds.length && feeds.some((feed) => feed.isRead === false)) {
//             setIsNewFeed(true);
//         } else {
//             setIsNewFeed(false);
//         }
//         setFeeds(feeds);
//     };

//     const changeData = () => socket.emit("initial_notifications_data", userData?.id || null);


//     console.log('feeds data ', feeds)


//     return (
//         <header className='header'>
//             <div className="container-fluid">
//                 <div className="row">
//                     <div className="col-xl-6 col-md-8 col-lg-8 col-6 align-self-center d-flex justify-content-start gap-3 align-items-center ">
//                         <div className="hamburger-wrapper">
//                             <a className='hamburger desktop'><img src={hamburgerIcon} alt="hamburgerIcon" onClick={sidebarActiveHandler} /></a>
//                             <a className='hamburger mobile'><img src={hamburgerIcon} alt="hamburgerIcon" onClick={sidebarMobileActiveHandler} /></a>
//                         </div>
//                         {showSearch && <div className="scan-new-profile-inner-content">
//                             <div className="search-filter-wrapper">
//                                 <div className="search-filter-bar dark">
//                                     <Select
//                                         value={selectedOption}
//                                         onChange={handleChange}
//                                         options={options}
//                                         // isClearable={true}
//                                         getOptionLabel={(option) => (
//                                             <div>
//                                                 <img src={option.icon} alt={option.label}
//                                                     style={{ width: '20px', marginRight: '10px' }} />
//                                                 {/*{option.label}*/}
//                                             </div>
//                                         )}
//                                     />
//                                     <div className="form-group">
//                                         <span>@</span>
//                                         <input value={usernameInput} onChange={(e) => setUsernameInput(e.target.value)} type="text" placeholder='Enter username here' className="form-control" />
//                                     </div>
//                                     <button disabled={submittingUsernameLoading} onClick={() => handleSearch()} className="btn-style simple dark">{submittingUsernameLoading ? 'Loading' : 'Search New Profile'}<img src={generateIcon} alt="generateIcon" /></button>
//                                 </div>
//                             </div>
//                         </div>}
//                     </div>
//                     <div className="col-xl-6 col-md-4 col-lg-4 col-6 align-self-center">
//                         <div className="right-menu">
//                             <div className="btns-wrapper">
//                                 {userData?.role == 'admin' && <button onClick={() => navigate('/authenticate')} className="btn-style small"><img src={thundarIcon} alt="thundarIcon" /> Dashboard  </button>}

//                                 {/* <div className="btn-div">
//                                     <p className="white">{userData?.subscription?.remaining_backups || 0}/{userData?.subscription?.total_backups || 0} downloads</p>
//                                     <ProgressBar now={Math.ceil(((userData?.subscription?.total_backups - userData?.subscription?.remaining_backups) / userData?.subscription?.total_backups * 100)) || 0} />
//                                 </div> */}
//                                 <button onClick={() => navigate('/pricing')} className="btn-style small"><img src={thundarIcon} alt="thundarIcon" /> Upgrade Plan  </button>
//                             </div>
//                             <a onClick={notificationHandler} className='bellIcon'><img src={bellIcon} alt="bellIcon" /><span className={isNewFeed ? `dot` : ' '}></span></a>
//                             <div className="select-mode">
//                                 <div className="toggle-btn">
//                                     <input type="checkbox"
//                                         id="switch"
//                                         className="checkbox" />

//                                     <label htmlFor="switch" className="toggle">
//                                         <img src={halfIconIcon} alt="halfIconIcon" className='halfMoonIcon' />
//                                         <img src={sunIcon} alt="sunIcon" className='sunIcon' />
//                                     </label>
//                                 </div>
//                                 <span>Darkmode</span>
//                             </div>
//                         </div>
//                         <NotificationsComponent notificationActive={notificationActive} data={feeds} isNewFeed={isNewFeed} setIsNewFeed={setIsNewFeed} />
//                     </div>
//                     <div className="col-12 d-md-none d-block mobile-filter mt-3 ">
//                         {showSearch && <div className="scan-new-profile-inner-content">
//                             <div className="search-filter-wrapper">
//                                 <div className="search-filter-bar dark">
//                                     <Select
//                                         value={selectedOption}
//                                         onChange={handleChange}
//                                         options={options}
//                                         // isClearable={true}
//                                         getOptionLabel={(option) => (
//                                             <div>
//                                                 <img src={option.icon} alt={option.label}
//                                                     style={{ width: '20px', marginRight: '10px' }} />
//                                                 {/*{option.label}*/}
//                                             </div>
//                                         )}
//                                     />
//                                     <div className="form-group">
//                                         <span>@</span>
//                                         <input value={usernameInput} onChange={(e) => setUsernameInput(e.target.value)} type="text" placeholder='Enter username here' className="form-control" />
//                                     </div>
//                                     <button onClick={() => handleSearch()} className="btn-style simple dark">Search Profile <img src={generateIcon} alt="generateIcon" /></button>
//                                 </div>
//                             </div>
//                         </div>}
//                     </div>
//                 </div>
//             </div>
//         </header>
//     )
// }


import React, { useEffect, useRef, useState } from 'react';
import socketIOClient from "socket.io-client";
import Select from "react-select";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Link, useNavigate } from 'react-router-dom';

// **************************** Images *****************************
import hamburgerIcon from "../assets/images/icons/hamburger-icon.png";
import bellIcon from "../assets/images/icons/bell-icon.png";
import halfIconIcon from "../assets/images/icons/half-moon-ico.png";
import sunIcon from "../assets/images/icons/sun-icon.png";
import thundarIcon from "../assets/images/icons/thundar-icon.png";
import generateIcon from "../assets/images/icons/generate-icon.png";
import NotificationsComponent from "./NotificationsComponent";
import axios from 'axios';
import logOutIcon from "../assets/images/icons/log-out-icon.png";
import userIcon from "../assets/images/icons/user-icon.png";
import { useAuth } from '../hooks/useAuth';
import { useSelector } from 'react-redux';

export const socket = socketIOClient(`${process.env.REACT_APP_SOCKET_SERVER}`, {
    transports: ['polling']
});

export default function HeaderComponent({ sidebarActiveHandler, sidebarMobileActiveHandler, handleChange, options, selectedOption, usernameInput, setUsernameInput, handleSearch, showSearch, submittingUsernameLoading }) {
    const [notificationActive, setNotificationActive] = useState(false);
    const navigate = useNavigate();
    const notificationIconRef = useRef(null);
    const notificationPopupRef = useRef(null);
    const [loadingNotifications, setLoadingNotifications] = useState(false)
    const [paginationModal, setPaginationModal] = useState({ page: 1, pageLimit: 10 })
    const [hasMore, setHasMore] = useState(false)
    const token = localStorage.getItem('app-access-token')

    // const {user} = useAuth()
    const {user} = useSelector(state => state.auth)

    const userData = user;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                notificationIconRef.current &&
                !notificationIconRef.current.contains(event.target) &&
                notificationPopupRef.current &&
                !notificationPopupRef.current.contains(event.target)
            ) {
                setNotificationActive(false);
            }
        };

        // Add event listener when the component mounts
        document.addEventListener('click', handleClickOutside);

        // Remove event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const notificationHandler = (event) => {
        event.stopPropagation();
        setNotificationActive(!notificationActive);
        socket.emit("check_all_notifications", userData?.id || null);
    };

    const getUserNotifications = async () => {
        try {
            setLoadingNotifications(true)
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/notifications/user-notifications`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                params: { page: paginationModal.page, limit: paginationModal.pageLimit, sortBy: '-createdAt' }
            })

            const data = response.data;
            setFeeds(prevFeeds => {
                const newFeeds = data?.results || [];
                const combinedFeeds = [...prevFeeds, ...newFeeds];

                // Remove duplicates
                const uniqueFeeds = Array.from(new Set(combinedFeeds.map(feed => feed.id)))
                    .map(id => combinedFeeds.find(feed => feed.id === id));

                return uniqueFeeds;
            });
            if (data?.page < data?.totalPages) {
                setHasMore(true)
            } else {
                setHasMore(false)
            }
            if (data?.results?.length && data?.results?.some((feed) => feed.isRead === false)) {
                setIsNewFeed(true);
            } else {
                setIsNewFeed(false);
            }
            setLoadingNotifications(false)
        } catch (error) {
            console.log('error fetching notifications ', error)
            setLoadingNotifications(false)
        }
    }

    const [feeds, setFeeds] = useState([]);
    const [isNewFeed, setIsNewFeed] = useState(false);

    useEffect(() => {
        // getUserNotifications()

        // socket.emit("initial_notifications_data", userData?.id || null);
        // socket.on("get_data", getData);
        socket.on("change_notification_data", getUserNotifications);
        return () => {
            // socket.off("get_data");
            socket.off("change_notification_data");
        };
    }, []);

    useEffect(() => {
        getUserNotifications()
    }, [paginationModal])

    const getData = (feeds) => {
        if (feeds.length && feeds.some((feed) => feed.isRead === false)) {
            setIsNewFeed(true);
        } else {
            setIsNewFeed(false);
        }
        setFeeds(feeds);
    };

    // const changeData = () => socket.emit("initial_notifications_data", userData?.id || null);
    // const changeData = () => getUserNotifications();

    console.log('feeds data ', feeds);

    return (
        <header className='header'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-6 col-md-8 col-lg-8 col-6 align-self-center d-flex justify-content-start gap-3 align-items-center ">
                        <div className="hamburger-wrapper">
                            <a className='hamburger desktop'><img src={hamburgerIcon} alt="hamburgerIcon" onClick={sidebarActiveHandler} /></a>
                            <a className='hamburger mobile'><img src={hamburgerIcon} alt="hamburgerIcon" onClick={sidebarMobileActiveHandler} /></a>
                        </div>
                        {showSearch && (
                            <div className="scan-new-profile-inner-content">
                                <div className="search-filter-wrapper">
                                    <div className="search-filter-bar dark">
                                        <Select
                                            value={selectedOption}
                                            onChange={handleChange}
                                            options={options}
                                            // isClearable={true}
                                            getOptionLabel={(option) => (
                                                <div>
                                                    <img src={option.icon} alt={option.label}
                                                        style={{ width: '20px', marginRight: '10px' }} />
                                                </div>
                                            )}
                                        />
                                        <div className="form-group">
                                            <span>@</span>
                                            <input value={usernameInput} onChange={(e) => setUsernameInput(e.target.value)} type="text" placeholder='Enter username here' className="form-control" />
                                        </div>
                                        <button disabled={submittingUsernameLoading} onClick={() => handleSearch()} className="btn-style simple dark">
                                            {submittingUsernameLoading ? 'Analyzing...' : 'Search New Profile'}
                                            <img src={generateIcon} alt="generateIcon" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-xl-6 col-md-4 col-lg-4 col-6 align-self-center">
                        <div className="right-menu">
                            <div className="btns-wrapper">
                                {userData?.role === 'admin' && (
                                    <button onClick={() => navigate('/authenticate')} className="btn-style small">
                                        <img src={thundarIcon} alt="thundarIcon" /> Dashboard
                                    </button>
                                )}
                                <button onClick={() => navigate('/pricing')} className="btn-style small">
                                    <img src={thundarIcon} alt="thundarIcon" /> Upgrade Plan
                                </button>
                            </div>
                            <a ref={notificationIconRef} onClick={notificationHandler} className='bellIcon'>
                                <img src={bellIcon} alt="bellIcon" /><span className={isNewFeed ? `dot` : ''}></span>
                            </a>
                            <div className="user-detail">
                                <div className="img-wrapper ">
                                    <Link style={{ cursor: "pointer" }} to='/setting'><img src={userData?.avatar?.includes('https') ? userData?.avatar : (userData?.avatar && userData?.avatar !== 'default.jpg') ? process.env.REACT_APP_ASSET_PATH + userData?.avatar :  userIcon} alt="avatarIcon" /></Link>
                                </div>
                                <div className="text-wrapper">
                                    <span className="strong white d-block">{userData?.name || ' ' }</span>
                                    <span className="small">{userData?.email || ' ' }</span>
                                </div>
                            </div>
                        </div>
                        <div ref={notificationPopupRef}>
                            <NotificationsComponent notificationActive={notificationActive} data={feeds} isNewFeed={isNewFeed} setIsNewFeed={setIsNewFeed} loadingNotifications={loadingNotifications} paginationModal={paginationModal} setPaginationModal={setPaginationModal} hasMore={hasMore} />
                        </div>
                    </div>
                    <div className="col-12 d-md-none d-block mobile-filter mt-3 ">
                        {showSearch && (
                            <div className="scan-new-profile-inner-content">
                                <div className="search-filter-wrapper">
                                    <div className="search-filter-bar dark">
                                        <Select
                                            value={selectedOption}
                                            onChange={handleChange}
                                            options={options}
                                            // isClearable={true}
                                            getOptionLabel={(option) => (
                                                <div>
                                                    <img src={option.icon} alt={option.label}
                                                        style={{ width: '20px', marginRight: '10px' }} />
                                                </div>
                                            )}
                                        />
                                        <div className="form-group">
                                            <span>@</span>
                                            <input value={usernameInput} onChange={(e) => setUsernameInput(e.target.value)} type="text" placeholder='Enter username here' className="form-control" />
                                        </div>
                                        <button disabled={submittingUsernameLoading} onClick={() => handleSearch()} className="btn-style simple dark">
                                            {submittingUsernameLoading ? 'Loading' : <div> Search Profile <img src={generateIcon} alt="generateIcon" /></div>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
}
