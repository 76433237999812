import React, { useEffect, useState } from 'react'
import { ProgressBar, Spinner } from 'react-bootstrap'
import { calculateEngagementTimeseries } from '../../../utilities/CalculateEngagemntSeries';

const VideosPostedOnDaysOnAverage = ({ analyticsData, analyticsDataLoading, startDate, endDate, daysFilter, yearFilter }) => {
  const [dailyDataVideos, setDailyDataVideos] = useState([])
  const [dailyDataEngagement, setDailyDataEngagement] = useState([])
  const [bestDayToPost, setBestDayToPost] = useState('');
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  useEffect(() => {
    // Initialize array to store data for each day
    const videosPerDay = {
      Sunday: 0,
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0
    }

    const engagementPerDay = {
      Sunday: 0,
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0
    }

    const isDateInRange = (date, start, end) => {
      const currentDate = new Date(date);
      return currentDate >= new Date(start) && currentDate <= new Date(end);
    };


    // Filter data based on date range or days filter
    const filterDataByDate = (data) => {
      const now = new Date();
      if (startDate && endDate) {
        return data.filter(entry => isDateInRange(entry["Date Posted"], startDate, endDate));
      }
      if (yearFilter == true) {
        const currentYear = now.getFullYear();
        return data.filter(entry => new Date(entry['Date Posted']).getFullYear() === currentYear);
      }
      else {
        if (daysFilter == 30) {
          const oneMonthAgo = new Date(now.setMonth(now.getMonth() - 1));
          return data.filter(entry => new Date(entry['Date Posted']) >= oneMonthAgo);
        } else if (daysFilter == 7) {
          const oneWeekAgo = new Date(now.setDate(now.getDate() - 7));
          return data.filter(entry => new Date(entry['Date Posted']) >= oneWeekAgo);
        }
        return data;
      }
    };

    // Iterate over all videos timeseries data and aggregate the statistics
    filterDataByDate(analyticsData?.videos_timeseries || []).forEach(entry => {
      const entryDate = new Date(entry['Date Posted']);
      const dayOfWeek = entryDate.getDay();
      const day = daysOfWeek[dayOfWeek];
      videosPerDay[day] += entry.Videos || 0;
    });

    const engagementSeries = calculateEngagementTimeseries(
      filterDataByDate(analyticsData?.likes_timeseries || []),
      filterDataByDate(analyticsData?.comments_timeseries || []),
      filterDataByDate(analyticsData?.shares_timeseries || []),
      filterDataByDate(analyticsData?.bookmarks_timeseries || [])
    );

    // Iterate over all engagement timeseries data and aggregate the statistics
    engagementSeries.forEach(entry => {
      const entryDate = new Date(entry['Date Posted']);
      const dayOfWeek = entryDate.getDay();
      const day = daysOfWeek[dayOfWeek];
      engagementPerDay[day] += entry.Engagement || 0;
    });

    // Calculate total engagement for the week
    const totalEngagement = Object.values(engagementPerDay).reduce((acc, curr) => acc + curr, 0);

    // Calculate engagement percentage per day
    const engagementPercentagePerDay = {};
    daysOfWeek.forEach(day => {
      engagementPercentagePerDay[day] = totalEngagement > 0 ? Math.floor((engagementPerDay[day] / totalEngagement) * 100) : 0;
    });

    // Find the best day to post
    const bestDay = daysOfWeek.reduce((best, day) => {
      return engagementPercentagePerDay[day] > engagementPercentagePerDay[best] ? day : best;
    }, 'Sunday');

    setDailyDataVideos(videosPerDay);      // Now you have the total array of data for videos and views for each day of the week
    setDailyDataEngagement(engagementPercentagePerDay);
    setBestDayToPost(bestDay);
    console.log("videos per day", videosPerDay);
  }, [analyticsData, startDate, endDate, daysFilter]);

  return (
    <div className="chart-content-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-xl-7">
            <div className="title">
              <strong className="white">
                Videos posted on days on average
              </strong>
              {analyticsDataLoading && <span
                className='white d-inline-flex gap-2'>  <Spinner
                  animation="grow" size="sm" /><span
                    className=' d-inline-block white'>Analyzing...</span></span>}
            </div>
            <div className="text">
              <span>
                Through comprehensive analysis, Saturday emerges as the
                pinnacle day for posting. Seize this strategic insight
                to optimize your content scheduling and amplify your
                reach when it matters most.
              </span>
            </div>
          </div>
          <div className="col-md-5"></div>
        </div>
      </div>
      <ul className="graph-ranks my-4">
        <li>
          <div className="dot" style={{ background: "#D9D9D9" }}></div>
          <span>None</span>
        </li>
        <li>
          <div className="dot" style={{ background: "#65B5DC" }}></div>
          <span>Lowest</span>
        </li>
        <li>
          <div className="dot" style={{ background: "#666DD8" }}></div>
          <span>Low</span>
        </li>
        <li>
          <div className="dot" style={{ background: "#A366DF" }}></div>
          <span>High</span>
        </li>
        <li>
          <div className="dot" style={{ background: "#E9269D" }}></div>
          <span>Highest</span>
        </li>
      </ul>
      <div className="video-posted-average-wrapper">
        <div className="video-posted-wrapper">
          <div className="title" style={{ background: "#EB55B4" }}>
            <span className="white strong">Saturday</span>
            {bestDayToPost == 'Saturday' && <span className="ex-small">👍 Best day to post</span>}
          </div>
          <div className="content">
            <span className="small white">Videos: {dailyDataVideos?.Saturday || 0}</span>
            <span className="small white">Engagement: {dailyDataEngagement?.Saturday}%</span>
            <ProgressBar now={dailyDataEngagement?.Saturday} />
          </div>
        </div>
        <div className="video-posted-wrapper">
          <div className="title" style={{ background: "#B58AE4" }}>
            <span className="white strong">Wednesday</span>
            {bestDayToPost == 'Wednesday' && <span className="ex-small">👍 Best day to post</span>}
          </div>
          <div className="content">
            <span className="small white">Videos: {dailyDataVideos?.Wednesday || 0}</span>
            <span className="small white">Engagement: {dailyDataEngagement?.Wednesday}%</span>
            <ProgressBar now={dailyDataEngagement?.Wednesday} />
          </div>
        </div>
        <div className="video-posted-wrapper">
          <div className="title" style={{ background: "#B58AE4" }}>
            <span className="white strong">Friday</span>
            {bestDayToPost == 'Friday' && <span className="ex-small">👍 Best day to post</span>}

          </div>
          <div className="content">
            <span className="small white">Videos: {dailyDataVideos?.Friday || 0}</span>
            <span className="small white">Engagement: {dailyDataEngagement?.Friday}%</span>
            <ProgressBar now={dailyDataEngagement?.Friday} />
          </div>
        </div>
        <div className="video-posted-wrapper">
          <div className="title" style={{ background: "#B58AE4" }}>
            <span className="white strong">Monday</span>
            {bestDayToPost == 'Monday' && <span className="ex-small">👍 Best day to post</span>}

          </div>
          <div className="content">
            <span className="small white">Videos: {dailyDataVideos?.Monday || 0}</span>
            <span className="small white">Engagement: {dailyDataEngagement?.Monday}%</span>
            <ProgressBar now={dailyDataEngagement?.Monday} />
          </div>
        </div>
        <div className="video-posted-wrapper">
          <div className="title" style={{ background: "#B58AE4" }}>
            <span className="white strong">Sunday</span>
            {bestDayToPost == 'Sunday' && <span className="ex-small">👍 Best day to post</span>}

          </div>
          <div className="content">
            <span className="small white">Videos: {dailyDataVideos?.Sunday || 0}</span>
            <span className="small white">Engagement: {dailyDataEngagement?.Sunday}%</span>
            <ProgressBar now={dailyDataEngagement?.Sunday} />
          </div>
        </div>
        <div className="video-posted-wrapper">
          <div className="title" style={{ background: "#858DE2" }}>
            <span className="white strong">Thursday</span>
            {bestDayToPost == 'Thursday' && <span className="ex-small">👍 Best day to post</span>}

          </div>
          <div className="content">
            <span className="small white">Videos: {dailyDataVideos?.Thursday || 0}</span>
            <span className="small white">Engagement: {dailyDataEngagement?.Thursday}%</span>
            <ProgressBar now={dailyDataEngagement?.Thursday} />
          </div>
        </div>
        <div className="video-posted-wrapper">
          <div className="title" style={{ background: "#EAB9FF" }}>
            <span className="white strong">Tuesday</span>
            {bestDayToPost == 'Tuesday' && <span className="ex-small">👍 Best day to post</span>}

          </div>
          <div className="content">
            <span className="small white">Videos: {dailyDataVideos?.Tuesday || 0}</span>
            <span className="small white">Engagement: {dailyDataEngagement?.Tuesday}%</span>
            <ProgressBar now={dailyDataEngagement?.Tuesday} />
          </div>
        </div>
        {/* {daysOfWeek.map(day => (
          <div className="video-posted-wrapper" key={day}>
            <div className="title" style={{ background: day === bestDayToPost ? "#EB55B4" : "#B58AE4" }}>
              <span className="white strong">{day}</span>
              {day === bestDayToPost && <span className="ex-small">👍 Best day to post</span>}
            </div>
            <div className="content">
              <span className="small white">Videos: {dailyDataVideos?.[day] || 0}</span>
              <span className="small white">Engagement: {dailyDataEngagement?.[day] || 0}%</span>
              <ProgressBar now={dailyDataEngagement?.[day] || 0} />
            </div>
          </div>
        ))} */}
      </div>
    </div>
  )
}

export default VideosPostedOnDaysOnAverage
