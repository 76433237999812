import React, { useEffect, useRef, useState } from 'react'
import { IoCalendarClear } from 'react-icons/io5'
import infoQuestionMark from "../../../assets/images/icons/info-question-mark-icon.png";
import LargeGraphChart2 from '../../LargeGraphChart2';
import Calendar from 'react-calendar';
import DoubleLineChart from "../../all-charts/DoubleLineChart";
import LargeGraphChartDistribution from '../../LargeGraphChartDistribution';
import { Spinner } from 'react-bootstrap';

const DistributionOfLikes = ({
    dateRange1,
    setDateRange1,
    handleDateRange1,
    analyticsData,
    profileData,
    calcLast30DaysVideos,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    analyticsDataLoading,
    analyticsDataLoading30d
}) => {

    const [selectingStartDate, setSelectingStartDate] = useState(true);
    const [daysFilter, setDaysFilter] = useState(null)
    const modalRef = useRef(null);


    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target) && !event.target.closest('.box-icon')) {
            setDateRange1(false);
        }
    };

    useEffect(() => {
        if (dateRange1) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dateRange1]);

    const handleSelectByDays = (days) => {
        if (days) {
            if (!isNaN(days)) {
                const today = new Date();
                const newStartDate = new Date(today.getTime() - days * 24 * 60 * 60 * 1000); // Calculate startDate by subtracting days
                setStartDate(newStartDate);
                setEndDate(today); // endDate is today
            }
        } else {
            setStartDate(null);
            setEndDate(null)
        }
    }

    useEffect(() => {
        handleSelectByDays(document.getElementById('lastDaysInput')?.value || parseInt(document.getElementById('upTodayInput')?.value))
    }, [parseInt(document.getElementById('lastDaysInput')?.value), parseInt(document.getElementById('upTodayInput')?.value)]);


    const handlePredefinedRange2 = (range) => {
        // alert(range)
        const currentDate = new Date();
        let newStartDate = new Date();
        let newEndDate = new Date();

        switch (range) {
            case 'today2':
                newStartDate = currentDate;
                newEndDate = currentDate;
                break;
            case 'yesterday':
                newStartDate = new Date(currentDate);
                newStartDate.setDate(currentDate.getDate() - 1);
                newEndDate = newStartDate;
                break;
            case 'week2':
                newStartDate = new Date(currentDate);
                newStartDate.setDate(currentDate.getDate() - currentDate.getDay());
                newEndDate = currentDate;
                break;
            case 'last-days2':
                const lastDays = parseInt(document.getElementById('lastDaysInput2')?.value);
                handleSelectByDays(lastDays)
                break;

            case 'month2':
                newStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                newEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                break;
            case 'lastMonth2':
                newStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
                newEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
                break;
            case 'thisYear2':
                newStartDate = new Date(currentDate.getFullYear(), 0, 1);
                newEndDate = new Date(currentDate.getFullYear(), 11, 31);
                break;
            case 'upToday2':
                const upTodayDays = parseInt(document.getElementById('upTodayInput2').value);
                handleSelectByDays(upTodayDays)
                break;
            case 'reset2':
                newStartDate = null;
                newEndDate = null
            // Add more cases as needed
            default:
                break;
        }
        // setDateRange([{ startDate, endDate, key: 'selection' }]);
        // setSelectedRange(range);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    const handleDateSelect = (selectedDate) => {
        if (selectingStartDate) {
            setStartDate(selectedDate);
        } else {
            setEndDate(selectedDate);
        }
        setSelectingStartDate(!selectingStartDate); // Toggle selectingStartDate flag
    };

    return (
        <div className="chart-section" style={{ borderRadius: '0 0 10px 10px' }}>
            <div className="section-heading">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-7 col-md-6 col-lg-6">
                            <div className="title">
                                <strong className="white">Distribution of likes</strong>
                                <div className="text">
                                    <div
                                        className="dot"
                                        style={{ background: "#FF6400" }}
                                    ></div>
                                    <span className="small">Average</span>
                                    <img src={infoQuestionMark} alt="infoQuestionMark" />
                                </div>
                            </div>
                            <p>
                                Is this account a one hit wonder or it creates content
                                that always get engagement
                            </p>
                        </div>
                        <div className="col-xl-5 col-md-6 col-lg-6">
                            <div className="date-wrapper">
                                {analyticsDataLoading30d ? <span
                                    className='white d-inline-flex gap-2'>  <Spinner
                                        animation="grow" size="sm" /><span
                                            className=' d-inline-block white'>Analyzing...</span></span> : <ul className="d-list">
                                    <li
                                        onClick={() =>
                                            setDaysFilter((pre) => pre == 7 ? null : (7))
                                        }
                                        className={`${daysFilter == 7 ? "white" : ""}`}
                                    >
                                        <span>7D</span>
                                    </li>
                                    <li
                                        onClick={() =>
                                            setDaysFilter((pre) => pre == 30 ? null : (30))
                                        }
                                        className={`${daysFilter == 30 ? "white" : ""}`}
                                    >
                                        <span>30D</span>
                                    </li>
                                    {/* <li
                                        onClick={() =>
                                            setDaysFilter((pre) => (null))
                                        }
                                        className={`${daysFilter == null ? "white" : ""}`}
                                    >
                                        <span>All</span>
                                    </li> */}
                                </ul>}
                                {endDate && analyticsDataLoading && <span
                                    className='white d-inline-flex gap-2'>  <Spinner
                                        animation="grow" size="sm" /><span
                                            className=' d-inline-block white'>Analyzing...</span></span>}
                                <div onClick={handleDateRange1} className="d-flex gap-2 align-items-center " style={{ cursor: 'pointer' }}>
                                    <div
                                        className={`box-icon ${dateRange1 ? "active" : ""}`}
                                    >
                                        <IoCalendarClear />
                                    </div>
                                    <p className="white">Set date range</p>
                                </div>
                            </div>
                            <div
                                ref={modalRef}
                                className={`select-time-main-wrapper chart ${dateRange1 ? "active" : ""
                                    }`}
                            >
                                <form action="#">
                                    <ul className="time" onChange={(e) => handlePredefinedRange2(e.target.value)}>
                                        <li>
                                            <input
                                                type="radio"
                                                id="last-days2"
                                                name="time2"
                                                value="last-days2"
                                            />
                                            <label htmlFor="last-days2">
                                                Last <input type="text" id="lastDaysInput2" /> Days
                                            </label>
                                        </li>
                                        <li>
                                            <input type="radio" id="week" name="time2" value="week2" />
                                            <label htmlFor="week2">This Week</label>
                                        </li>
                                        <li>
                                            <input type="radio" id="month2" name="time2" value="month2" />
                                            <label htmlFor="month2">
                                                This Month{" "}
                                                {`(${new Date().toLocaleString("en-US", {
                                                    month: "long",
                                                    timeZone: "UTC",
                                                })})`}
                                            </label>
                                        </li>
                                        <li>
                                            <input
                                                type="radio"
                                                id="lastMonth2"
                                                name="time2"
                                                value="lastMonth2"
                                            />
                                            <label htmlFor="lastMonth2">
                                                Last Month
                                                {/*{`(${getLastMonthName()})`}*/}
                                            </label>
                                        </li>
                                        <li>
                                            <input
                                                type="radio"
                                                id="reset2"
                                                name="time2"
                                                value="reset2"
                                            />
                                            <label htmlFor="reset2">
                                                All Time
                                            </label>
                                        </li>
                                        <li>
                                            <input
                                                type="radio"
                                                id="thisYear2"
                                                name="time2"
                                                value="thisYear2"
                                            />
                                            <label htmlFor="thisYear2">
                                                This Year {`(${new Date().getFullYear()})`}
                                            </label>
                                        </li>

                                    </ul>
                                </form>
                                <div className="calendar-wrapper">
                                    <div className="select-time">
                                        <div className="select">
                                            <span className="white">
                                                {startDate ? startDate.toLocaleDateString() : ' '}
                                            </span>
                                        </div>
                                        <span className="white mx-3">-</span>
                                        <div className="select">
                                            <span className="white">
                                                {endDate ? endDate.toLocaleDateString() : ' '}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="calandar">
                                        {/* <CalendarComponent /> */}
                                        <Calendar onChange={handleDateSelect} value={selectingStartDate ? startDate : endDate} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
            <div className="chart-content-wrapper">
                <div className="graph-wrapper" style={{ margin: "40px 0 50px" }}>
                    {/* analyticsData?.likes_timeseries */}
                    <div className="graph-title">
                        <div className="dot" style={{ background: '#FF165D' }}></div>
                        <span>Likes</span></div>
                    <LargeGraphChartDistribution data={analyticsData} startDate={startDate} endDate={endDate} daysFilter={daysFilter} />
                    {/* <DoubleLineChart /> */}
                </div>
            </div>
            <div className="average-text pink">
                <p className="small">
                    This account has show consistency in their growth and shows that it’s not a one hit wonder.
                </p>
            </div>
        </div>
    )
}

export default DistributionOfLikes