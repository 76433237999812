// import HeatMap from "@uiw/react-heat-map";
// import Tooltip from "@uiw/react-tooltip";

// //https://github.com/uiwjs/react-heat-map

// const value = [
//     {
//         date: "2016/05/01",
//         sumTime: 180
//     },
//     {
//         date: "2016/05/02",
//         sumTime: 300
//     },
//     {
//         date: "2016/05/03",
//         sumTime: 600
//     },
//     {
//         date: "2016/05/04",
//         sumTime: 600
//     },
//     {
//         date: "2016/05/05",
//         sumTime: 900
//     },
//     {
//         date: "2016/05/06",
//         sumTime: 300
//     },
//     {
//         date: "2016/05/07",
//         sumTime: 300
//     },
//     {
//         date: "2016/05/08",
//         sumTime: 600
//     },
//     {
//         date: "2016/05/09",
//         sumTime: 720
//     },
//     {
//         date: "2016/05/10",
//         sumTime: 900
//     },
//     {
//         date: "2016/05/11",
//         sumTime: 3600
//     },
//     {
//         date: "2016/05/12",
//         sumTime: 20000
//     }
// ];

// export default function ContributionChart({
//     dateRange1,
//     handleDateRange1,
//     analyticsData,
//   }) {
//     return (
//         <div className="github-chart">
//             <HeatMap
//                 width={600}
//                 value={value}
//                 startDate={new Date("2016/05/01")}
//                 rectSize={21}
//                 panelColors={{
//                     0: "#38414F",
//                     150: "#B58AE4",
//                     240: "#858DE2",
//                     540: "#EB55B4",
//                     840: "#c2533a"
//                 }}
//                 rectRender={(props, data) => {
//                     // if (!data.count) return <rect {...props} />;
//                     return (
//                         <Tooltip
//                             key={props.key}
//                             placement="top"
//                             content={`sumTime: ${data.sumTime || 0}`}
//                         >
//                             <rect {...props} rx={5} />
//                         </Tooltip>
//                     );
//                 }}
//                 rectProps={{
//                     rx: 5
//                 }}
//             />
//         </div>
//     );
// }


// import React, { useMemo } from "react";
// import HeatMap from "@uiw/react-heat-map";
// import Tooltip from "@uiw/react-tooltip";

// export default function ContributionChart({analyticsData }) {
//     const heatmapValues = useMemo(() => {
//         if (!analyticsData || !analyticsData.videos_timeseries_by_week) return [];

//         // Flatten the nested arrays and map each day object to the required format
//         const flattenedData = analyticsData.videos_timeseries_by_week.flat();

//         // Log flattened data for debugging
//         console.log("Flattened Data:", flattenedData);

//         const transformedData = flattenedData.map((day) => ({
//             date: new Date(day["Date Posted"]), // Convert Date Posted to Date object
//             sumTime: parseInt(day["Videos"], 10), // Ensure Videos is parsed as integer
//         }));

//         return transformedData;
//     }, [analyticsData]);

//     // Log heatmapValues for debugging
//     console.log("heatmapValues:", heatmapValues);

//     return (
//         <div className="github-chart">
//             <HeatMap
//                 width={800}
//                 value={heatmapValues} // Pass the transformed value array here
//                 startDate={new Date(heatmapValues[0]?.date)} // Adjust startDate according to your data
//                 rectSize={21}
//                 panelColors={{
//                     0: "#38414F",
//                     1: "#B58AE4",
//                     2: "#858DE2",
//                     3: "#EB55B4",
//                     4: "#c2533a",
//                 }}
//                 rectRender={(props, data) => (
//                     <Tooltip
//                         key={props.key}
//                         placement="top"
//                         content={`Videos: ${data.sumTime || 0}`}
//                     >
//                         <rect {...props} rx={5} />
//                     </Tooltip>
//                 )}
//                 rectProps={{
//                     rx: 5,
//                 }}
//             />
//         </div>
//     );
// }





import React, { useMemo } from "react";
import { FaCalendarDays } from "react-icons/fa6";
import HeatMap from "@uiw/react-heat-map";
// import Tooltip from "@uiw/react-tooltip";
import Button from 'react-bootstrap/Button';

import { BsCameraVideoFill } from "react-icons/bs";
import { BsFillEyeFill } from "react-icons/bs";
import { MdTextsms } from "react-icons/md";

import platformIcon from '../../assets/images/icons/tiktok-small-icon.png'
import youtubeIcon from '../../assets/images/icons/youtube-icon.png'
import instagramIcon from '../../assets/images/icons/instagram-reels-icon.png'
import videoImg from '../../assets/images/video-img-3.png'
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ShortenNumber from "../../utilities/ShortenNumber";

function getVideosDaily(entry) {
    const videosByDay = {};

    const dateObj = new Date(entry['Date Posted']);
    const day = ('0' + dateObj.getDate()).slice(-2);
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const year = dateObj.getFullYear().toString().slice(-2); // Get the last two digits of the year

    const date = `${day}-${month}-${year}`; // Format the date as dd-mm-yy

    if (!videosByDay[date]) {
        videosByDay[date] = [];
    }

    if (entry?.Videos > 0) {
        const videos = Array.isArray(entry?.details?.[1]) ? entry?.details?.[1] : [];
        const thumbnails = Array.isArray(entry?.details?.[2]) ? entry?.details?.[2] : [];

        for (let i = 0; i < videos.length; i++) {
            videosByDay[date].push({
                link: videos[i],
                thumbnail: thumbnails[i]
            });
        }
    }

    // Convert the result to an array of objects for each day
    const result = Object.keys(videosByDay).map(date => ({
        year: date,
        videos: videosByDay[date]
    }));

    return result;
}

export default function ContributionChart({ analyticsData, platform }) {
    // const heatmapValues = useMemo(() => {
    //     if (!analyticsData || !analyticsData.videos_timeseries_by_week) return [];

    //     // Flatten the nested arrays and map each day object to the required format
    //     const flattenedData = analyticsData.videos_timeseries_by_week.flat();

    //     // Log flattened data for debugging
    //     console.log("Flattened Data:", flattenedData);

    //     const transformedData = flattenedData.map((day) => ({
    //         date: new Date(day["Date Posted"]), // Convert Date Posted to Date object
    //         sumTime: parseInt(day["Videos"], 10), // Ensure Videos is parsed as integer
    //     }));

    //     return transformedData;
    // }, [analyticsData]);

    const heatmapValues = useMemo(() => {
        if (!analyticsData || !analyticsData.videos_timeseries_by_week) return [];

        // Flatten the nested arrays and map each day object to the required format
        const flattenedData = analyticsData.videos_timeseries_by_week.flat();

        // Log flattened data for debugging
        console.log("Flattened Data:", flattenedData);

        const transformedData = flattenedData.map((day) => {
            const details = day.details || [];
            const videos = getVideosDaily(day || [])

            return {
                date: new Date(day["Date Posted"]), // Convert Date Posted to Date object
                sumTime: parseInt(day["Videos"], 10), // Ensure Videos is parsed as integer
                totalComments: day["Comments"],
                totalViews: day["Views"],
                details: videos
            };
        });

        return transformedData;
    }, [analyticsData]);


    // Log heatmapValues for debugging
    console.log("heatmapValues:", heatmapValues);

    // Define the color scale for the heatmap
    const panelColors = {
        0: "#38414F",
        1: "rgba(101, 181, 220, 1)",
        2: "rgba(102, 109, 216, 1)",
        3: "rgba(163, 102, 223, 1)",
        4: "rgba(233, 38, 157, 1)",
    };

    // Define a function to determine the color based on sumTime value
    const getColorForValue = (value) => {
        if (value >= 4) return panelColors[4];
        if (value >= 3) return panelColors[3];
        if (value >= 2) return panelColors[2];
        if (value >= 1) return panelColors[1];
        return panelColors[0];
    };

    const GitHubChartTooltip = (props, data) => (
        <Tooltip {...props} className='custom-tooltip' >
            <div className='git-hub-chart-tooltip-wrapper'>
                <div className="date-wrapper">
                    <FaCalendarDays />
                    <span className="ex-small">{data?.date}</span>
                </div>
                <div className="images-wrapper">
                    {/* <div className="img-main-wrapper">
                    <div className="img">
                        <img src={videoImg} alt="videoImg"/>
                    </div>
                    <div className="icon">
                        <img src={platformIcon} alt="platformIcon"/>
                        <sup>L</sup>
                    </div>
                </div> */}
                    {data?.details?.[0]?.videos?.map((item, index) => {
                        return (
                            <div className="img-main-wrapper">
                                <div className="img">
                                    <a href={`${item?.link}`} target="_blank"><img src={item?.thumbnail} alt="videoImg" /></a>
                                </div>
                                <div className="icon">
                                    <img src={platform == 'instagram' ? instagramIcon : platform == 'youtube' ? youtubeIcon : platformIcon} alt="platformIcon" />
                                    <sup>L</sup>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="total-detail-wrapper">
                    <div className="total">
                        <BsCameraVideoFill />
                        <div className="text">
                            <span className="ex-small">Total videos posted</span>
                            <strong className="ex-small">{ShortenNumber(data?.sumTime)}</strong>
                        </div>
                    </div>
                    <div className="total">
                        <BsFillEyeFill />
                        <div className="text">
                            <span className="ex-small">Total views</span>
                            <strong className="ex-small">{ShortenNumber(data?.totalViews)
                            }</strong>
                        </div>
                    </div>
                    <div className="total">
                        <MdTextsms />
                        <div className="text">
                            <span className="ex-small">Total comments</span>
                            <strong className="ex-small">{ShortenNumber(data?.totalComments)}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </Tooltip>
    );

    return (
        <div className="github-chart">
            <HeatMap
                width={800}
                value={heatmapValues} // Pass the transformed value array here
                startDate={new Date(heatmapValues[0]?.date)} // Adjust startDate according to your data
                rectSize={21}
                panelColors={panelColors}
                rectRender={(props, data) => {
                    const fillColor = getColorForValue(data.sumTime || 0);
                    return (
                        // <Tooltip
                        //     // key={props.key}
                        //     placement="top"
                        //     content={<GitHubChartTooltip/>}
                        // >
                        // </Tooltip>
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={GitHubChartTooltip(props, data)}
                            className="git-hub-chart-tooltip"
                            trigger="click"
                        >
                            <rect {...props} fill={fillColor} rx={5} />
                            {/* <Button variant="success">Hover me to see</Button> */}
                        </OverlayTrigger>
                    );
                }}
                rectProps={{
                    rx: 5,
                }}
            />


        </div>
    );
}
