
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings } from '../store/settingsActions';
import { ApiRequests } from '../services/apiRequests';

const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("app-access-token");
  const userSettings = useSelector(state => state.settings.userSettings);
  const [user, setUser] = useState({})
  console.log('userSettings', userSettings)

  // console.log('user is ', user)
  // useEffect(() => {
  //   dispatch(fetchSettings({accessToken}));
  // }, [dispatch]);

  return (
    <SettingsContext.Provider value={{ userSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsContext);
