import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto'; // Import Chart object
import ShortenNumber from '../utilities/ShortenNumber';
import moment from 'moment';

export default function LineChartVideos({ analyticsData }) {
    const [videosTimeSeries, setVideosTimeSeries] = useState([]);
    const canvasRef = useRef(null);

    // useEffect(() => {
    //     if (!analyticsData) return;
        
    //     const videosTimeSeries = analyticsData?.videos_timeseries;
    //     const videosTimeSeriesByYear = {};

    //     videosTimeSeries?.forEach((videos) => {
    //         const date = new Date(videos['Date Posted']);
    //         const year = date.getFullYear();
    //         const videoCount = Number(videos.Videos); // Ensure Videos is treated as a number

    //         if (!isNaN(videoCount)) { // Check for NaN
    //             if (videosTimeSeriesByYear[year]) {
    //                 videosTimeSeriesByYear[year].totalVideos += videoCount;
    //                 videosTimeSeriesByYear[year].count += 1;
    //             } else {
    //                 videosTimeSeriesByYear[year] = {
    //                     totalVideos: videoCount,
    //                     count: 1
    //                 };
    //             }
    //         }
    //     });

    //     const averagedVideosTimeSeries = Object.entries(videosTimeSeriesByYear).map(([year, data]) => ({
    //         year: year,
    //         averageVideos: (data.totalVideos)
    //     }));

    //     setVideosTimeSeries(averagedVideosTimeSeries);
    // }, [analyticsData]);

    useEffect(() => {
        if (!analyticsData) return;
    
        const videosTimeSeries = analyticsData?.videos_timeseries;
        const videosTimeSeriesByMonth = {};
    
        videosTimeSeries?.forEach((videos) => {
            const date = new Date(videos['Date Posted']);
            const year = date.getFullYear();
            const month = date.getMonth() + 1; // getMonth() returns 0-11, so add 1 to make it 1-12
            const yearMonth = moment(date).format('MM/YY'); // Format as YYYY-MM
            const videoCount = Number(videos.Videos); // Ensure Videos is treated as a number
    
            if (!isNaN(videoCount)) { // Check for NaN
                if (videosTimeSeriesByMonth[yearMonth]) {
                    videosTimeSeriesByMonth[yearMonth].totalVideos += videoCount;
                    videosTimeSeriesByMonth[yearMonth].count += 1;
                } else {
                    videosTimeSeriesByMonth[yearMonth] = {
                        totalVideos: videoCount,
                        count: 1
                    };
                }
            }
        });
    
        const averagedVideosTimeSeries = Object.entries(videosTimeSeriesByMonth).map(([yearMonth, data]) => ({
            year: yearMonth,
            averageVideos: (data.totalVideos)
        }));
    
        setVideosTimeSeries(averagedVideosTimeSeries);
    }, [analyticsData]);
    
    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 400); // Adjust according to your chart size
        gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
        gradient.addColorStop(0, 'rgba(231, 173, 90, 0.4)'); // Adjust as needed

        const chartInstance = new Chart(ctx, {
            type: 'line',
            data: {
                labels: videosTimeSeries.map((data) => data.year),
                datasets: [
                    {
                        label: 'Average Videos per Year',
                        backgroundColor: gradient,
                        borderColor: 'rgba(231, 173, 90, 1)',
                        borderWidth: 2,
                        fill: true,
                        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                        pointBorderColor: 'rgba(0, 0, 0, 0)',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
                        data: videosTimeSeries.map((data) => data.averageVideos),
                        lineTension: 0.4, // Adjust the tension value for smoothness
                    }
                ]
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                scales: {
                    x: {
                        ticks:{
                            color:'#D9D9D9',
                        },
                        grid: {
                            display: false,
                        },
                    },
                    y: {
                        ticks: {
                            beginAtZero: true,
                            color:'#D9D9D9',
                            callback: function (value) {
                                return ShortenNumber(value);
                            },
                            maxTicksLimit:5
                        },
                        grid: {
                            display: true,
                            color: 'rgba(100, 111, 124, .2)',
                        },
                    }
                },
            },
        });

        return () => {
            chartInstance.destroy(); // Cleanup chart instance when component unmounts
        };
    }, [videosTimeSeries]);

    return (
        <div className='graph-chart-wrapper'>
            <canvas ref={canvasRef} />
        </div>
    );
}
