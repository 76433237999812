'use client';

import { useEffect, useState } from 'react';
import { basePath } from '../services/constents';

export function useAuth() {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [userSettings, setUserSettings] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getUser = async () => {
    const Authorization = `Bearer ${localStorage.getItem('app-access-token')}`
      const res = await fetch(`${basePath}/auth/authenticate`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': Authorization,
        },
      });
      if (!res.ok) {
        setProfile(null)
        setUser(null)
        setLoading(false)
        setUserSettings(null)
        return null;
      }

      const user = await res.json();
      if (user.user.email) {
        setProfile(user.user)
        setUser(user.user)
      }
      if (user.settings) {
        setUserSettings(user.settings)
      }
      setLoading(false)
    }
    if (!user) {
      try {
        setLoading(true);
        getUser()
      } catch (e) {
        console.log(e)
        setLoading(false)
      }
    }
  }, [user]);

  const updateSettings = (settings) => {
    setUserSettings(settings)
    return
  }

  return { user, profile, loading, userSettings,updateSettings };
}
