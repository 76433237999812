import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto'; // Import Chart object
import ShortenNumber from '../utilities/ShortenNumber';
import moment from 'moment';

export default function LineChartsComments({ analyticsData }) {
    const [commentsTimeSeries, setCommentsTimeSeries] = useState([]);
    const canvasRef = useRef(null);

    // useEffect(() => {
    //     if (!analyticsData) return;

    //     var commentsTimeSeries = analyticsData?.comments_timeseries;
    //     var commentsTimeSeriesByYear = {};

    //     commentsTimeSeries?.forEach((comments) => {
    //         var date = new Date(comments['Date Posted']);
    //         var year = date.getFullYear();

    //         if (commentsTimeSeriesByYear[year]) {
    //             commentsTimeSeriesByYear[year].totalComments += comments.Comments;
    //             commentsTimeSeriesByYear[year].count += 1;
    //         } else {
    //             commentsTimeSeriesByYear[year] = {
    //                 totalComments: comments.Comments,
    //                 count: 1
    //             };
    //         }
    //     });

    //     var averagedCommentsTimeSeries = Object.entries(commentsTimeSeriesByYear).map(([year, data]) => ({
    //         year: year,
    //         averageComments: (data.totalComments / data.count).toFixed(0)
    //     }));

    //     setCommentsTimeSeries(averagedCommentsTimeSeries);

    // }, [analyticsData]);

    useEffect(() => {
        if (!analyticsData) return;
    
        const commentsTimeSeries = analyticsData?.comments_timeseries;
        const commentsTimeSeriesByDay = {};
    
        commentsTimeSeries?.forEach((comments) => {
            const date = new Date(comments['Date Posted']);
            const day = moment(date).format('MM/DD/YY'); // Get the date string in YYYY-MM-DD format
    
            if (commentsTimeSeriesByDay[day]) {
                commentsTimeSeriesByDay[day].totalComments += comments.Comments;
                commentsTimeSeriesByDay[day].count += 1;
            } else {
                commentsTimeSeriesByDay[day] = {
                    totalComments: comments.Comments,
                    count: 1
                };
            }
        });
    
        const averagedCommentsTimeSeries = Object.entries(commentsTimeSeriesByDay).map(([day, data]) => ({
            year: day,
            averageComments: (data.totalComments / data.count).toFixed(0) // Average comments per day
        }));
    
        setCommentsTimeSeries(averagedCommentsTimeSeries);
    
    }, [analyticsData]);
    

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 400); // Adjust according to your chart size
        gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
        gradient.addColorStop(0, '#A162F700'); // Adjust as needed

        const chartInstance = new Chart(ctx, {
            type: 'line',
            data: {
                labels: commentsTimeSeries?.map((data) => data.year),
                datasets: [
                    {
                        label: 'Average Comments per Year',
                        backgroundColor: gradient,
                        borderColor: '#A162F7',
                        borderWidth: 2,
                        fill: true,
                        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                        pointBorderColor: 'rgba(0, 0, 0, 0)',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
                        data: commentsTimeSeries.map((data) => data.averageComments),
                        lineTension: 0.4, // Adjust the tension value for smoothness
                    }
                ]
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                scales: {
                    x: {
                        ticks : {
                            color:'#D9D9D9',
                        },
                        grid: {
                            display: false,
                        },
                    },
                    y: {
                        ticks: {
                            color:'#D9D9D9',
                            beginAtZero: true,
                            callback: function (value) {
                                return ShortenNumber(value);
                            },
                            maxTicksLimit:5
                        },
                        grid: {
                            display: true,
                            color: 'rgba(100, 111, 124, .2)',
                        },
                    }
                },
            },
        });

        return () => {
            chartInstance.destroy(); // Cleanup chart instance when component unmounts
        };
    }, [commentsTimeSeries]);

    return (
        <div className='graph-chart-wrapper'>
            <canvas ref={canvasRef} />
        </div>
    );
}
