import React, { useEffect, useState } from 'react'
import GithubContrubutionChart from '../../GithubContrubutionChart'
import { IoCalendarClear } from "react-icons/io5";
// import Calendar from "react-calendar";
import Dropdown from "react-bootstrap/Dropdown";
import ContributionChart from "../../all-charts/ContributionChart";
import { Spinner } from 'react-bootstrap';

const VideosPostedByDayOfTheWeekMainNew = ({
    dateRange1,
    handleDateRange1,
    analyticsData,
    profileData,
    analyticsDataLoading,
    startDate, 
    setStartData, 
    endDate, 
    setEndDate,
    daysFilter,
    setDaysFilter,
    dateRange,
    setDateRange,
    handleDateRange

}) => {
    const [years, setYears] = useState([])

    const [yearFilter, setYearFilter] = useState(new Date()?.getFullYear());
    const [typeFilter, setTypeFilter] = useState('Days posted');

    const [filteredAnalyticsData, setFilteredAnalyticsData] = useState(analyticsData);
    const [bestWeek, setBestWeek] = useState("")

    const getAllYears = () => {
        if (!analyticsData?.videos_timeseries_by_week) {
            return []
        }
        const yearsSet = new Set();

        analyticsData?.videos_timeseries_by_week?.forEach(week => {
            week.forEach(entry => {
                const year = new Date(entry['Date Posted']).getFullYear();
                yearsSet.add(year);
            });
        });

        return Array.from(yearsSet).sort((a, b) => b - a); // Convert to array and sort
    };

    useEffect(() => {
        // Filter data based on selected filters
        const filterData = () => {
            let filteredData = { ...analyticsData, videos_timeseries_by_week: [] };

            if (yearFilter) {
                // Filter by year
                filteredData.videos_timeseries_by_week = analyticsData.videos_timeseries_by_week
                    ?.map(week =>
                        week.filter(entry =>
                            new Date(entry['Date Posted']).getFullYear() === parseInt(yearFilter)
                        )
                    )
                    .filter(week => week.length > 0);
            } else {
                // No year filter applied, copy the data as is
                filteredData.videos_timeseries_by_week = [...analyticsData.videos_timeseries_by_week];
            }

            if (daysFilter) {
                // Filter by daysFilter (7 or 30 days)
                const now = new Date();
                const pastDate = new Date(now);
                pastDate.setDate(now.getDate() - daysFilter);

                filteredData.videos_timeseries_by_week = filteredData.videos_timeseries_by_week
                    ?.map(week =>
                        week.filter(entry =>
                            new Date(entry['Date Posted']) >= pastDate
                        )
                    )
                    .filter(week => week.length > 0);
            }

            if (startDate && endDate) {
                // Filter by startDate and endDate
                const start = new Date(startDate);
                const end = new Date(endDate);

                filteredData.videos_timeseries_by_week = filteredData.videos_timeseries_by_week
                    ?.map(week =>
                        week.filter(entry =>
                            new Date(entry['Date Posted']) >= start && new Date(entry['Date Posted']) <= end
                        )
                    )
                    .filter(week => week.length > 0);
            }

            if (typeFilter === 'Highest Engaged Days') {
                // Filter by highest engaged days
                filteredData.videos_timeseries_by_week = filteredData.videos_timeseries_by_week
                    ?.map(week =>
                        week.filter(entry => entry['Engagement'] && entry['Engagement'] > 0)
                    )
                    .filter(week => week.length > 0);
            
                // Sort weeks by total engagement
                filteredData.videos_timeseries_by_week.sort((a, b) => {
                    const totalEngagementA = a.reduce((sum, entry) => sum + entry['Engagement'], 0);
                    const totalEngagementB = b.reduce((sum, entry) => sum + entry['Engagement'], 0);
                    return totalEngagementB - totalEngagementA;
                });
            }

            // Calculate the best week to post based on the number of videos
            let bestWeek = null;
            let maxVideos = 0;

            filteredData.videos_timeseries_by_week?.forEach((week, weekIndex) => {
                const numVideos = week.length;
                if (numVideos > maxVideos) {
                    maxVideos = numVideos;
                    bestWeek = weekIndex;
                }
            });

            // Convert week index to week number and month
            let bestWeekLabel = '';
            if (bestWeek !== null) {
                const firstEntry = filteredData.videos_timeseries_by_week[bestWeek][0];
                const date = new Date(firstEntry['Date Posted']);
                const weekNumber = Math.ceil((date.getDate() - 1) / 7) + 1;
                const month = date.toLocaleString('default', { month: 'long' });
                bestWeekLabel = `${weekNumber} ${month}`;
            }

            console.log('Best week to post:', bestWeekLabel);

            setFilteredAnalyticsData(filteredData);
            setBestWeek(bestWeekLabel);
        };

        filterData();
    }, [yearFilter, analyticsData, typeFilter, daysFilter, startDate, endDate]);

    useEffect(() => {
        const yearsData = getAllYears()
        setYears(yearsData)
    },[analyticsData])

    return (
        <div className="chart-section">
            <div className="section-heading">
                <div className="container-fluid">
                    <div className="row">
                        <div className="align-self-center col-lg-12 col-xl-7">
                            <div className="title">
                                <strong className="white">Videos posted by day of the week</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="chart-content-wrapper contribution-chart-main-wrapper">
                <div className="section-heading" style={{ padding: '0 20px 50px' }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="align-self-center col-md-7 col-lg-7 col-xl-7">
                                <div className="titles">
                                    <ul className="labels">
                                        <li><div className="dot" style={{ background: 'rgba(56, 65, 79, 1)' }}></div><span>None</span></li>
                                        <li><div className="dot" style={{ background: 'rgba(101, 181, 220, 1)' }}></div><span>Lowest</span></li>
                                        <li><div className="dot" style={{ background: 'rgba(102, 109, 216, 1)' }}></div><span>Low</span></li>
                                        <li><div className="dot" style={{ background: 'rgba(163, 102, 223, 1)' }}></div><span>High</span></li>
                                        <li><div className="dot" style={{ background: 'rgba(233, 38, 157, 1)' }}></div><span>Highest</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="align-self-center col-xl-5 col-lg-5 col-md-5">
                                <div className="date-wrapper">
                                    <div
                                        className={`select-wrapper`} style={{ background: 'rgba(22, 28, 44, 1)', padding: '0 0 0 15px' }}
                                    >
                                        {analyticsDataLoading && <span
                                            className='white d-inline-flex gap-2'>  <Spinner
                                                animation="grow" size="sm" /><span
                                                    className=' d-inline-block white'>Analyzing...</span></span>}
                                        <IoCalendarClear width={20} color='rgba(37, 188, 145, 1)' />

                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                {yearFilter}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {/* <Dropdown.Item onClick={() => setYearFilter('2024')}>2024</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setYearFilter('2023')}>2023</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setYearFilter('2022')}>2022</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setYearFilter('2021')}>2021</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setYearFilter('2020')}>2020</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setYearFilter('2019')}>2019</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setYearFilter('2018')}>2018</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setYearFilter('2017')}>2017</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setYearFilter('2016')}>2016</Dropdown.Item> */}
                                                {years?.map((year, index) => {
                                                    return (
                                                        <Dropdown.Item key={index} onClick={() => setYearFilter(year)}>{year}</Dropdown.Item>
                                                    )
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </div>
                                    <div
                                        className={`select-wrapper`} style={{ background: 'rgba(22, 28, 44, 1)' }}
                                    >
                                        <Dropdown style={{ width: 200 }}>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                {typeFilter}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => setTypeFilter('Days posted')}>Days posted</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setTypeFilter('Highest Engaged Days')}>Highest Engaged Days</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <GithubContrubutionChart
                    dateRange1={dateRange1}
                    handleDateRange1={handleDateRange1}
                    analyticsData={filteredAnalyticsData}
                /> */}
                <ContributionChart
                    analyticsData={filteredAnalyticsData} platform={profileData?.platform} />
                <div className="average-text light-pink" style={{ margin: '30px 0 0 20px' }}>
                    <p className="small">
                        Through comprehensive analysis, Week {bestWeek || ''} emerges as the pinnacle week for posting. Seize
                        this
                        strategic insight to optimize your content scheduling and amplify your reach when it matters
                        most.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default VideosPostedByDayOfTheWeekMainNew