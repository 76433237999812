import React, { useEffect, useRef, useState } from "react";
import infoQuestionMark from "../../../assets/images/icons/info-question-mark-icon.png";
import cyanChartSmallImg from "../../../assets/images/cyan-chart-small-img.png";
import greenChartSmallImg from "../../../assets/images/green-chart-small-img.png";
import yellowChartSmallImg from "../../../assets/images/yelow-chart-small-img.png";
import purpleChartSmallImg from "../../../assets/images/purple-chart-small-img.png";
import pinkChartSmallImg from "../../../assets/images/pink-chart-small-img.png";
import orangeChartSmallImg from "../../../assets/images/orange-chart-small-img.png";
import { IoCalendarClear, IoGrid } from "react-icons/io5";
import Calendar from "react-calendar";
import LargeGraphChart from "../../LargeGraphChart";
import { BsFillEyeFill, BsHeart, BsHeartFill } from "react-icons/bs";
import smsPurpleIcon from "../../../assets/images/icons/Icon.png";
import { PiShareFatFill } from "react-icons/pi";
import bookmarkCyanIcon from "../../../assets/images/icons/bookmark-cyan-icon.png";
import heartRedIcon from "../../../assets/images/icons/heart-red-icon.png";
import StatsChartComponent from "../../StatsChartComponent";
import { PiVideoCameraFill } from "react-icons/pi";
import { FaComment, FaUser } from "react-icons/fa";
import { FcLike } from "react-icons/fc";
import { IoBookmark } from "react-icons/io5";
import LargeGraphChart1 from "../../LargeGraphChart1";
import DoubleLineChart from "../../all-charts/DoubleLineChart";
import { FaTextSlash } from "react-icons/fa6";
import { Spinner } from "react-bootstrap";

const AccountGrowthCharts = ({
  dateRange,
  setDateRange,
  handleDateRange,
  smallChartTabs,
  setSmallChartTabs,
  smallChartTabsHandler,
  profileData,
  chartData,
  setChartData,
  chartTabs,
  setChartTabs,
  analyticsData,
  calcLast30DaysVideos,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  daysFilter,
  setDaysFilter,
  chartLabels,
  pointVideos,
  yearFilter,
  setYearFilter,
  ref,
  analyticsDataLoading, analyticsDataLoading30d
}) => {
  // const [dateRange, setDateRange] = useState([{ startDate: addDays(new Date(), -30), endDate: new Date(), key: 'selection' }]);
  const [selectedRange, setSelectedRange] = useState("30D");
  const [filteredData, setFilteredData] = useState(chartData);
  const [selectingStartDate, setSelectingStartDate] = useState(true);

  const modalRef = useRef(null);


  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target) && !event.target.closest('.box-icon')) {
      setDateRange(false);
    }
  };

  useEffect(() => {
    if (dateRange) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dateRange]);

  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");

  // useEffect(() => {
  //     // Filter chart data based on the selected date range
  //     const filtered = chartData.filter(dataPoint => {
  //         const date = new Date(dataPoint.date); // Ensure your data has a `date` field
  //         return date >= dateRange[0].startDate && date <= dateRange[0].endDate;
  //     });
  //     setFilteredData(filtered);
  // }, [dateRange, chartData]);

  // const handleSelect = (ranges) => {
  //     setDateRange([ranges.selection]);
  //     setSelectedRange('custom');
  // };

  const handleSelectByDays = (days) => {
    if (days) {
      if (!isNaN(days)) {
        const today = new Date();
        const newStartDate = new Date(today.getTime() - days * 24 * 60 * 60 * 1000); // Calculate startDate by subtracting days
        setStartDate(newStartDate);
        setEndDate(today); // endDate is today
      }
    } else {
      setStartDate(null);
      setEndDate(null)
    }
  }

  useEffect(() => {
    handleSelectByDays(document.getElementById('lastDaysInput')?.value || parseInt(document.getElementById('upTodayInput')?.value))
  }, [parseInt(document.getElementById('lastDaysInput')?.value), parseInt(document.getElementById('upTodayInput')?.value)]);



  const handlePredefinedRange = (range) => {
    // alert(range)
    const currentDate = new Date();
    let newStartDate = new Date();
    let newEndDate = new Date();

    switch (range) {
      case "today":
        newStartDate = currentDate;
        newEndDate = currentDate;
        break;
      case "yesterday":
        newStartDate = new Date(currentDate);
        newStartDate.setDate(currentDate.getDate() - 1);
        newEndDate = newStartDate;
        break;
      case "week":
        newStartDate = new Date(currentDate);
        newStartDate.setDate(currentDate.getDate() - currentDate.getDay());
        newEndDate = currentDate;
        break;
      case "last-days":
        const lastDays = parseInt(document.getElementById('lastDaysInput')?.value);
        handleSelectByDays(lastDays)
        break;

      case "month":
        newStartDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        newEndDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        );
        break;
      case "lastMonth":
        newStartDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          1
        );
        newEndDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          0
        );
        break;
      case "thisYear":
        // newStartDate = new Date(currentDate.getFullYear(), 0, 1);
        // newEndDate = new Date(currentDate.getFullYear(), 11, 31);
        newStartDate = null
        newEndDate = null
        setYearFilter(true)
        break;
      case "upToday":
        const upTodayDays = parseInt(
          document.getElementById("upTodayInput").value
        );
        handleSelectByDays(upTodayDays);
        break;
      case "reset":
        newStartDate = null;
        newEndDate = null;
        setYearFilter(false)
      // Add more cases as needed
      default:
        break;
    }
    // setDateRange([{ startDate, endDate, key: 'selection' }]);
    // setSelectedRange(range);
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const handleDateSelect = (selectedDate) => {
    if (selectingStartDate) {
      setStartDate(selectedDate);
    } else {
      setEndDate(selectedDate);
    }
    setSelectingStartDate(!selectingStartDate); // Toggle selectingStartDate flag
  };

  return (
    <div className="chart-section">
      <div className="section-title">
        <strong className="white large">Account Growth Charts</strong>
      </div>
      <div className="section-heading">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-xl-7">
              <div className="title">
                <strong className="white">Video count over time</strong>
                {/* <div className="text">
                  <div className="dot" style={{ background: "#FF6400" }}></div>
                  <span className="small">Average</span>
                  <img src={infoQuestionMark} alt="infoQuestionMark" />
                </div> */}
              </div>
              <p>
                This graph shows the number of videos @{profileData?.username}{" "}
                <span> </span>
                have posted over time. It helps you track thier content
                production and see if there are any trends in their posting
                frequency.
              </p>
            </div>
            <div className="col-lg-6 col-xl-5">
              <div className="date-wrapper">
                {analyticsDataLoading30d ? <span
                  className='white d-inline-flex gap-2'>  <Spinner
                    animation="grow" size="sm" /><span
                      className=' d-inline-block white'>Analyzing...</span></span> : <ul className="d-list">
                  <li
                    onClick={() =>
                      setDaysFilter((pre) => pre == 7 ? null : (7))
                    }
                    className={`${daysFilter == 7 ? "white" : ""}`}
                  >
                    <span>7D</span>
                  </li>
                  <li
                    onClick={() =>
                      setDaysFilter((pre) => pre == 30 ? null : (30))
                    }
                    className={`${daysFilter == 30 ? "white" : ""}`}
                  >
                    <span>30D</span>
                  </li>
                  {/* <li
                    onClick={() =>
                      setDaysFilter((pre) => (null))
                    }
                    className={`${daysFilter == null ? "white" : ""}`}
                  >
                    <span>All</span>
                  </li> */}
                </ul>}
                {endDate && analyticsDataLoading && <span
                  className='white d-inline-flex gap-2'>  <Spinner
                    animation="grow" size="sm" /><span
                      className=' d-inline-block white'>Analyzing...</span></span>}
                <div onClick={handleDateRange} className="d-flex gap-2 align-items-center " style={{cursor : 'pointer'}}>

                <div
                  className={`box-icon ${dateRange ? "active" : ""}`}
                >
                  <IoCalendarClear />
                  {/* <Calendar onChange={handleDateSelect} value={selectingStartDate ? startDate : endDate} /> */}
                </div>
                <p className="white">Set date range</p>
                </div>
               
              </div>
              <div
                ref={modalRef}
                className={`select-time-main-wrapper chart ${dateRange ? "active" : ""
                  }`}
              >
                <form action="#">
                  <ul
                    className="time"
                    onChange={(e) => handlePredefinedRange(e.target.value)}
                  >
                    <li>
                      <input
                        type="radio"
                        id="Today"
                        name="time"
                        value="today"
                      />
                      <label htmlFor="Today">Today</label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        id="Yesterday"
                        name="time"
                        value="yesterday"
                      />
                      <label htmlFor="Yesterday">Yesterday</label>
                    </li>
                    <li>
                      <input type="radio" id="week" name="time" value="week" />
                      <label htmlFor="week">This Week</label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        id="last-days"
                        name="time"
                        value="last-days"
                      />
                      <label htmlFor="last-days">
                        Last <input type="text" id="lastDaysInput" /> Days
                      </label>
                    </li>

                    <li>
                      <input
                        type="radio"
                        id="month"
                        name="time"
                        value="month"
                      />
                      <label htmlFor="month">
                        This Month{" "}
                        {`(${new Date().toLocaleString("en-US", {
                          month: "long",
                          timeZone: "UTC",
                        })})`}
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        id="lastMonth"
                        name="time"
                        value="lastMonth"
                      />
                      <label htmlFor="lastMonth">
                        Last Month
                        {/*{`(${getLastMonthName()})`}*/}
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        id="thisYear"
                        name="time"
                        value="thisYear"
                      />
                      <label htmlFor="thisYear">
                        This Year {`(${new Date().getFullYear()})`}
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        id="upToday"
                        name="time"
                        value="upToday"
                      />
                      <label htmlFor="upToday">
                        <input type="text" id="upTodayInput" /> Days up to today
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        id="reset"
                        name="time"
                        value="reset"
                      />
                      <label htmlFor="reset">Reset</label>
                    </li>
                  </ul>
                </form>
                <div className="calendar-wrapper">
                  <div className="select-time">
                    <div className="select">
                      <span className="white">
                        {startDate ? startDate.toLocaleDateString() : " "}
                      </span>
                    </div>
                    <span className="white mx-3">-</span>
                    <div className="select">
                      <span className="white">
                        {endDate ? endDate.toLocaleDateString() : " "}
                      </span>
                    </div>
                  </div>
                  <div className="calandar">
                    {/* <CalendarComponent /> */}
                    <Calendar
                      onChange={handleDateSelect}
                      value={selectingStartDate ? startDate : endDate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="chart-content-wrapper">
        <div className="row">
          <div className="col-xl-2  col-lg-3 col-md-3  align-self-center">
            <ul className="lists">
              <li>
                <button
                  className={`${smallChartTabs == "video" ? "active" : ""}`}
                  onClick={() => setSmallChartTabs("video")}
                >
                  <PiVideoCameraFill style={{ color: "#FF165D" }} /> Videos
                </button>
              </li>
              <li>
                <button
                  className={`${smallChartTabs == "Views" ? "active" : ""}`}
                  onClick={() => setSmallChartTabs("Views")}
                >
                  <BsFillEyeFill style={{ color: "#00F2EA" }} /> Views
                </button>
              </li>
              {/* <li>
                <button
                  className={`${smallChartTabs == "Followers" ? "active" : ""}`}
                  onClick={() => setSmallChartTabs("Followers")}
                >
                  <FaUser style={{ color: "#FF6400" }} /> Followers
                </button>
              </li> */}
              <li>
                <button
                  className={`${smallChartTabs == "Likes" ? "active" : ""}`}
                  onClick={() => setSmallChartTabs("Likes")}
                >
                  {/* <FcLike style={{ color: "#5352B0" }} />  */}
                  <BsHeartFill style={{ color: "#5352B0" }} />
                  Likes
                </button>
              </li>

              {/*<li><button className={`${smallChartTabs == 'shares' ? 'active' : ''}`} onClick={() => setSmallChartTabs('shares')}><PiShareFatFill style={{ color: '#5FC754' }} /> Shares</button></li>*/}
              <li>
                <button
                  className={`${smallChartTabs == "Bookmarks" ? "active" : ""}`}
                  onClick={() => setSmallChartTabs("Bookmarks")}
                >
                  <IoBookmark style={{ color: "#34A853" }} /> Bookmarks
                </button>
              </li>
              <li>
                <button
                  className={`${smallChartTabs == "comment" ? "active" : ""}`}
                  onClick={() => setSmallChartTabs("comment")}
                >
                  <img src={smsPurpleIcon} alt="chartsBlackIcon" /> Comments
                </button>
              </li>
              <li>
                <button
                  className={`${smallChartTabs == "all" ? "active" : ""}`}
                  onClick={() => setSmallChartTabs("all")}
                >
                  <IoGrid style={{ color: "white" }} /> All
                </button>
              </li>
            </ul>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-9 align-self-center">
            <div className="graph-wrapper">
              {/* <div className="graph-title">
                                <div className="dot" style={{ background: '#FF165D' }}></div>
                                <span>{smallChartTabs}</span></div> */}
              <LargeGraphChart1
                data={chartData}
                smallChartTabs={smallChartTabs}
                analyticsData={analyticsData}
                chartTabs={chartTabs}
                colors={
                  smallChartTabs == "comment"
                    ? {
                      gradient: "rgba(253, 189, 97, 0.06)",
                      borderColor: "#FDBD61",
                    }
                    : smallChartTabs == "Views"
                      ? {
                        gradient: "rgba(25, 47, 62, 1)",
                        // borderColor: "rgb(0, 242, 234)",
                        borderColor: "#00F2EA",
                      }
                      : smallChartTabs == "video"
                        ? {
                          gradient: "rgba(66, 30, 56, 1)",
                          borderColor: "#FF165D",

                        }
                        : smallChartTabs == "shares"
                          ? {
                            gradient: "rgba(33, 73, 58, 1)",
                            borderColor: "rgb(52, 168, 83)",
                          }
                          : smallChartTabs == "Bookmarks"
                            ? {
                              gradient: "rgba(33, 73, 58, 1)",
                              borderColor: "#34A853",
                            }
                            : smallChartTabs == "Likes"
                              ? {
                                gradient: "rgba(83, 82, 176, 0.06)",
                                borderColor: "#5352B0",
                              }
                              : smallChartTabs == "Followers"
                                ? {
                                  gradient: "rgba(66, 43, 39, 1)",
                                  borderColor: "#FF6400",
                                }
                                : {
                                  gradient: "#FFFFFF",
                                  borderColor: "#FFFFFF",
                                }
                }
                daysFilter={daysFilter}
                chartLabels={chartLabels}
                pointVideos={pointVideos}
                profileData={profileData}
              />
              {/* <DoubleLineChart margin={true} /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="average-text" style={{ margin: "30px 0 0 20px" }}>
        <p className="small">
          @{profileData?.username} <span> </span>
          posted <span>{calcLast30DaysVideos || 0} videos</span> in the{" "}
          <span>30 days</span>
          {/* , accounts of similar size post {profileData?.score == 2 ? 5 : profileData?.score == 4 ? 10 : profileData?.score == 5 ? 20 : profileData?.score == 8 ? 30 : 60} videos at an */}
          {/* average. */}
        </p>
      </div>
      {/* <div className="chart-tabs-wrapper">
                <div
                    className={`chart-tab ${
                        smallChartTabs == "video" ? "active" : ""
                    }`}
                    onClick={() => smallChartTabsHandler("video")}
                >
                    <div className="img-wrapper">
                        <img src={pinkChartSmallImg} alt="cyanChartSmallImg"/>
                    </div>
                    <div className="text">
                        <p>Videos</p>
                    </div>
                </div>
                <div
                    className={`chart-tab ${
                        smallChartTabs == "Views" ? "active" : ""
                    }`}
                    onClick={() => smallChartTabsHandler("Views")}
                >
                    <div className="img-wrapper">
                        <img src={cyanChartSmallImg} alt="cyanChartSmallImg"/>
                    </div>
                    <div className="text">
                        <p>Views</p>
                    </div>
                </div>
                <div
                    className={`chart-tab ${
                        smallChartTabs == "Followers" ? "active" : ""
                    }`}
                    onClick={() => smallChartTabsHandler("Followers")}
                >
                    <div className="img-wrapper">
                        <img src={orangeChartSmallImg} alt="cyanChartSmallImg"/>
                    </div>
                    <div className="text">
                        <p>Followers</p>
                    </div>
                </div>
                <div
                    className={`chart-tab ${
                        smallChartTabs == "Likes" ? "active" : ""
                    }`}
                    onClick={() => smallChartTabsHandler("Likes")}
                >
                    <div className="img-wrapper">
                        <img src={purpleChartSmallImg} alt="cyanChartSmallImg"/>
                    </div>
                    <div className="text">
                        <p>Likes</p>
                    </div>
                </div>
                <div
                    className={`chart-tab ${
                        smallChartTabs == "Bookmarks" ? "active" : ""
                    }`}
                    onClick={() => smallChartTabsHandler("Bookmarks")}
                >
                    <div className="img-wrapper">
                        <img src={greenChartSmallImg} alt="cyanChartSmallImg"/>
                    </div>
                    <div className="text">
                        <p>Bookmarks</p>
                    </div>
                </div>
                <div
                    className={`chart-tab ${
                        smallChartTabs == "comment" ? "active" : ""
                    }`}
                    onClick={() => smallChartTabsHandler("comment")}
                >
                    <div className="img-wrapper">
                        <img src={yellowChartSmallImg} alt="cyanChartSmallImg"/>
                    </div>
                    <div className="text">
                        <p>Comments</p>
                    </div>
                </div>
            </div>*/}
    </div>
  );
};

export default AccountGrowthCharts;
