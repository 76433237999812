


import React, {useEffect, useRef, useState} from 'react';
import Chart from 'chart.js/auto'; // Import Chart object
import moment from 'moment';

export default function LineChartLikesPerDayVideos({ analyticsData }) {
    // const [likesTimeSeries, setLikesTimeSeries] = useState([])
    // useEffect(() => {
    //   if (!analyticsData) return
    //   var likesTimeSeries = (analyticsData?.likes_timeseries)
    //   //if likesTimeSeries length is bigger than 100, calculate the total views  for each month, instad hcing the data for each day 
    //   if (likesTimeSeries?.length > 100) {
  
    //     var likesTimeSeriesByMonth = {}
    //     likesTimeSeries.forEach((likes) => {
  
    //       var date = new Date(likes['Date Posted'])
    //       var month = date.getMonth()
    //       //get month word 
    //       month = new Date(date.getFullYear(), month, 1).toLocaleString('default', { month: 'short' })
    //       var year = date.getFullYear()
    //       var monthYear = month + '-' + year
    //       if (likesTimeSeriesByMonth[monthYear]) {
    //         likesTimeSeriesByMonth[monthYear].Likes += likes.Likes
    //       } else {
    //         likesTimeSeriesByMonth[monthYear] = {
    //           Likes: likes.Likes,
    //           'Date Posted': monthYear
    //         }
    //       }
    //     }
    //     )
  
    //     likesTimeSeries = Object.values(likesTimeSeriesByMonth)
    //   }
    //   setLikesTimeSeries(likesTimeSeries)
  
  
  
    // }, [analyticsData])
    const canvasRef = useRef(null);
    const [perDayVideosSeries, serPerDayVideosSeries] = useState([])
    // useEffect(() => {
    //     if (!analyticsData) return;
        
    //     var likesTimeSeries = analyticsData?.videos_timeseries || [];

    //     // If you don't want to aggregate by month and just use the per day data
    //     var processedLikesTimeSeries = likesTimeSeries.map((likes) => {
    //         // Example: Ensure date is in a specific format if needed
    //         var date = new Date(likes['Date Posted']).toLocaleDateString('default', {
    //             year: 'numeric',
    //             month: 'short',
    //             day: 'numeric'
    //         });
    //         return {
    //             ...likes,
    //             'Date Posted': date
    //         };
    //     });

    //     serPerDayVideosSeries(processedLikesTimeSeries);

    // }, [analyticsData]);
    useEffect(() => {
        if (!analyticsData) return;

        const currentDate = new Date();
        const last30DaysDate = new Date(currentDate.setDate(currentDate.getDate() - 30));

        const likesTimeSeries = analyticsData?.videos_timeseries || [];

        // Filter the data to include only entries from the last 30 days
        const filteredLikesTimeSeries = likesTimeSeries?.filter((entry) => {
            const entryDate = new Date(entry['Date Posted']);
            return entryDate >= last30DaysDate;
        });

        // Process the filtered data
        const processedLikesTimeSeries = filteredLikesTimeSeries.map((entry) => {
            const date = new Date(entry['Date Posted']);
            const formattedDate = moment(date).format('MM/DD/YY');
            return {
                ...entry,
                'Date Posted': formattedDate
            };
        });
        

        serPerDayVideosSeries(processedLikesTimeSeries);

    }, [analyticsData]);
    useEffect(() => {
        // Create gradient
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, '400'); // Adjust according to your chart size
        gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
        gradient.addColorStop(0, 'rgba(66, 43, 39, 1)'); // A djust as needed

        // Apply gradient as background color
        const chartInstance = new Chart(ctx, {
            type: 'line',
            data: {
                labels: perDayVideosSeries?.map((share) => share['Date Posted']),
                datasets: [
                    {
                        label: 'Dataset',
                        backgroundColor: gradient,
                        // borderColor: 'rgba(255, 22, 93, 1)',
                        borderColor: '#FF7A37',
                        borderWidth: 2,
                        fill: true,
                        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                        pointBorderColor: 'rgba(0, 0, 0, 0)',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
                        data: perDayVideosSeries?.map((like) => (like?.Videos?.toFixed(0))),
                        lineTension: 0.4, // Adjust the tension value for smoothness
                        zIndex: 1,
                    }
                ]
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color:'#D9D9D9',
                        },
                        grid: {
                            display: false,
                            z: 99
                        }
                    },
                    y: {
                        ticks: {
                            color:'#D9D9D9',
                            beginAtZero: true,
                            maxTicksLimit:5
                        },
                        grid: {
                            display: true,
                            color: 'rgba(100, 111, 124, .2)',
                            zIndex: 9999,
                            drawOnChartArea: true,
                        },
                    }
                },

            },
        });

        return () => {
            chartInstance.destroy(); // Cleanup chart instance when component unmounts
        };
    }, [analyticsData, perDayVideosSeries]);

    return (
        <div className='graph-chart-wrapper'>
            <canvas ref={canvasRef}/>
        </div>
    );
}
