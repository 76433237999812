import { buildStyles, CircularProgressbar, CircularProgressbarWithChildren } from "react-circular-progressbar";
import ShortenNumber from "../utilities/ShortenNumber";
import rightWhiteArrow from '../assets/images/icons/right-white-arrow.png';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ChangingProgressProvider from "./ChangingProgressProvider";
import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export default function LongVsShortVideosBig({ profiledata, daysFilter, startDate, endDate }) {
    const [shortVideos, setShortvideos] = useState([])
    const [longVideos, setLongVideos] = useState([])
    const [shortPercentage, setShortPercentage] = useState(0)
    const [longPercentage, setLongPercentage] = useState(0)
    const [shortViews, setShortViews] = useState(0)
    const [longViews, setLongViews] = useState(0)
    const videosData = useSelector((state) => state?.reels?.reelsData);

    useEffect(() => {
        const filterVideosByDate = (videos, daysFilter, startDate, endDate) => {
            const now = dayjs();
            return videos.filter((video) => {
                const videoDate = dayjs(video?.date || video?.["Date Posted"]);
                if (daysFilter) {
                    return now.diff(videoDate, 'day') <= daysFilter;
                } else if (startDate && endDate) {
                    return videoDate?.isBetween(startDate, endDate, null, '[]');
                }
                return true; // If no filter is applied, include all videos
            });
        };

        const totalVideos = filterVideosByDate(videosData || [], daysFilter, startDate, endDate);
        const shortVideos = [];
        const longVideos = [];
        let shortViews = 0;
        let longViews = 0;

        totalVideos?.forEach((item) => {
            const duration = profiledata?.platform === 'instagram' ? item?.Duration : item.duration;
            const views = profiledata?.platform === 'instagram' ? item?.Views : item.views;

            if (duration > 30) {
                longVideos.push(item);
                longViews += views;
            } else {
                shortVideos.push(item);
                shortViews += views;
            }
        });

        setShortvideos(shortVideos);
        setLongVideos(longVideos);
        setShortViews(shortViews);
        setLongViews(longViews);

        const shortVideosPercentage = (shortVideos.length / totalVideos.length) * 100;
        const longVideosPercentage = (longVideos.length / totalVideos.length) * 100;

        setShortPercentage(Math.ceil(shortVideosPercentage || 0));
        setLongPercentage(Math.ceil(longVideosPercentage || 0));

    }, [videosData, profiledata, daysFilter, startDate, endDate]);

    return (
        <div className="main-wrapper padding">
            <strong className="white title">Long VS Short Videos</strong>
            <div className="row">
                <div className="col-xl-7 col-lg-6 col-md-7">
                    <div className="progress-bar-wrapper">
                        {/*    <CircularProgressbar
                            value={shortPercentage}
                            text={`${shortPercentage}%`}
                            counterClockwise={true}
                        />*/}

                        <ChangingProgressProvider values={[shortPercentage]}>
                            {value => (
                                <CircularProgressbarWithChildren
                                    value={shortPercentage}
                                    circleRatio={0.75}
                                    styles={buildStyles({
                                        rotation: 1 / 2 + 1 / 8,
                                        trailColor: '#FF165D',
                                        pathColor: '#00E0FF', // Adjust the color to match the image
                                        pathTransitionDuration: 0.5,
                                        strokeDasharray: '1, 4', // Adjusted pattern for visible dots
                                        path: {
                                            strokeDasharray: '1, 5', // Create the dot pattern
                                        },
                                        trail: {
                                            strokeLinecap: 'butt',
                                        },
                                    })}
                                >
                                    <div className='text-wrapper'>
                                        <span className='white'>{shortPercentage > longPercentage ? 'Short' : 'Long'} Views</span>
                                        <h2 className='white'>{`${shortPercentage > longPercentage ? shortPercentage : longPercentage}%`}</h2>
                                    </div>
                                </CircularProgressbarWithChildren>
                            )}
                        </ChangingProgressProvider>

                    </div>
                    <p className="small">
                        Through comprehensive analysis, @{profiledata?.username}{" "}
                        is doing better with short form videos below 60 seconds.
                    </p>
                </div>
                <div className="col-xl-5 col-lg-6 col-md-5">
                    <div className="views-wrapper">
                        <div className="content">
                            <span className='white small mb-2'>Short Views</span>
                            <div className="text">
                                <span>Views:     </span>
                                <strong>       {ShortenNumber(shortViews)} </strong>
                            </div>
                            <div className="text">
                                <span>Videos:     </span>
                                <strong>       {ShortenNumber(shortVideos?.length)} </strong>
                            </div>
                            <div className="text">
                                <span>Percentage:</span>
                                <strong>    {shortPercentage}%</strong>
                            </div>
                        </div>
                        <div className="content">
                            <span className='white small mb-2'>Long Views</span>
                            <div className="text">
                                <span>Views:     </span>
                                <strong>  {ShortenNumber(longViews)} </strong>
                            </div>
                            <div className="text">
                                <span>Videos:     </span>
                                <strong>  {ShortenNumber(longVideos?.length)} </strong>
                            </div>
                            <div className="text">
                                <span>Percentage:</span>
                                <strong> {longPercentage}%</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}