import React from 'react'

import videoImg1 from "../assets/images/video-img-1.png";
import tikTokFullLogo from "../assets/images/icons/tikTok-full-logo.png";
import instagramLogo from "../assets/images/icons/instagram-reels-icon.png";
import youtubeLogo from "../assets/images/icons/youtube-icon.png";
import pinIcon from "../assets/images/icons/pin-icon.png";

import { BsFillHeartFill } from "react-icons/bs";
import { HiMiniChatBubbleOvalLeftEllipsis } from "react-icons/hi2";
import { PiShareFatFill } from "react-icons/pi";
import { RiBookmarkFill } from "react-icons/ri";
import ShortenNumber from '../utilities/ShortenNumber';
import {FaCalendarAlt, FaPlay} from "react-icons/fa";
import {GoClockFill} from "react-icons/go";
import moment from 'moment';

function formatDuration(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    if (minutes > 0) {
        return `${minutes}m ${remainingSeconds}s`;
    } else {
        return `${remainingSeconds}s`;
    }
}

export default function VideoComponent({ item, platform, selectedVideos, setSelectedVideos }) {
    return (
        <div className="video-wrapper">
            <div className="img-wrapper">
                <a href={platform == "instagram" ? item.Link : item.link} target="_blank">
                    <img src={platform === "instagram"
                        ? `https://instagramserver.tokbackup.com/proxy?media_url=${encodeURIComponent(
                            item.thumbnail
                        )}`
                        : item.thumbnail || item.avatar || videoImg1} alt="videoImg1"/>
                </a>
               {item?.isPinned == true &&  <img src={pinIcon} alt="pinIcon" className='pin-icon' />}
            </div>

            {/* <div className="content-wrapper">
                <img src={platform == 'instagram' ? instagramLogo : platform == 'youtube' ? youtubeLogo : tikTokFullLogo}
                     alt="tikTokFullLogo" className="logo"/>
            </div> */}

            <div className="content-wrapper">
                {/*<img src={tikTokFullLogo} alt="tikTokFullLogo" className="logo"/>*/}
                <ul className="list left">
                    <li>
                        <div className="icon">
                            <a><FaCalendarAlt/></a>
                            <span
                                className=" white">{moment(item.date || item['Date Posted']).format('MM/DD/YY')}</span>
                        </div>
                    </li>
                    <li>
                        <div className="icon">
                            <a><FaPlay/></a>
                            <span
                                className=" white">{ShortenNumber(item?.views || item?.Views)}</span>
                        </div>
                    </li>
                </ul>
                <ul className="list right">
                    <li>
                        <div className="icon">
                            <a><BsFillHeartFill/></a>
                            <span className=" white">{ShortenNumber(
                                platform == "instagram" ? item.Likes : item.likes || item.Likes
                            )}</span>
                        </div>
                    </li>
                    <li>
                        <div className="icon">
                            <a href="#"><HiMiniChatBubbleOvalLeftEllipsis/></a>
                            <span className=" white">{ShortenNumber(
                                platform == "instagram" ? item.Comments : item.comments || item.Comments
                            )}</span>
                        </div>
                    </li>
                    <li>
                        <div className="icon">
                            <a><RiBookmarkFill/></a>
                            <span className=" white">{ShortenNumber(item.bookmarks || item?.Bookmarks)}</span>
                        </div>
                    </li>
                    <li>
                        <div className="icon">
                            <a><PiShareFatFill/></a>
                            <span className=" white">{ShortenNumber(item.shares || item?.Shares)}</span>
                        </div>
                    </li>
                    <li>
                        <div className="icon">
                            <a><GoClockFill/></a>
                            <span
                                className=" white">{formatDuration(item?.duration == 0  ? 0 : item?.duration || item?.Duration)}</span>
                        </div>
                    </li>
                </ul>
                <div className="check-box-wrapper"><label className="label"><input checked={selectedVideos.has(
                    platform == "instagram" ? item["Video ID"] : `${item.id}`
                )}
                                                                                   onChange={(e) => {
                                                                                       const updatedSelection = new Set(selectedVideos);
                                                                                       if (e.target.checked) {
                                                                                           updatedSelection.add(
                                                                                               platform == "instagram" ? item["Video ID"] : `${item.id}` || item["Video ID"]
                                                                                           );
                                                                                       } else {
                                                                                           updatedSelection.delete(
                                                                                               platform == "instagram" ? item["Video ID"] : `${item.id}` || item["Video ID"]
                                                                                           );
                                                                                       }
                                                                                       setSelectedVideos(updatedSelection);
                                                                                   }} type="checkbox"/><span
                    className="checkmark"></span></label></div>
            </div>
        </div>
    )
}