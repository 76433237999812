import React, { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import { IoBookmark, IoCalendarClear, IoGrid } from "react-icons/io5";
import TimePostingPatterns from "./TimePostingPatterns";
import cyanChartSmallImg from "../../../assets/images/cyan-chart-small-img.png";
import greenChartSmallImg from "../../../assets/images/green-chart-small-img.png";
import yellowChartSmallImg from "../../../assets/images/yelow-chart-small-img.png";
import purpleChartSmallImg from "../../../assets/images/purple-chart-small-img.png";
import pinkChartSmallImg from "../../../assets/images/pink-chart-small-img.png";
import orangeChartSmallImg from "../../../assets/images/orange-chart-small-img.png";
import { PiShareFatFill, PiVideoCameraFill } from "react-icons/pi";
import { BsFillEyeFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import smsPurpleIcon from "../../../assets/images/icons/sms-purple-icon.png";
import { FcLike } from "react-icons/fc";
import LargeGraphChart from "../../LargeGraphChart";
import DoubleLineChart from "../../all-charts/DoubleLineChart";
import GreenLargeChart from "../../all-charts/GreenLargeChart";
import { TbWaveSawTool } from "react-icons/tb";
import { Spinner } from "react-bootstrap";

const TimePostingPatternsMain = ({
  dateRange,
  setDateRange,
  handleDateRange,
  smallChartTabsHandler,
  chartData,
  smallChartTabs,
  setSmallChartTabs,
  analyticsData,
  chartTabs,
  setChartTabs,
  profileData,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  daysFilter,
  setDaysFilter,
  chartLabels,
  yearFilter,
  setYearFilter,
  analyticsDataLoading,
  analyticsDataLoading30d

}) => {

  const [selectingStartDate, setSelectingStartDate] = useState(true);

  const modalRef = useRef(null);


  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target) && !event.target.closest('.box-icon')) {
      setDateRange(false);
    }
  };

  useEffect(() => {
    if (dateRange) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dateRange]);

  const handleSelectByDays = (days) => {
    if (days) {
      if (!isNaN(days)) {
        const today = new Date();
        const newStartDate = new Date(today.getTime() - days * 24 * 60 * 60 * 1000); // Calculate startDate by subtracting days
        setStartDate(newStartDate);
        setEndDate(today); // endDate is today
      }
    } else {
      setStartDate(null);
      setEndDate(null)
    }
  }


  useEffect(() => {
    handleSelectByDays(document.getElementById('lastDaysInput')?.value || parseInt(document.getElementById('upTodayInput')?.value))
  }, [parseInt(document.getElementById('lastDaysInput')?.value), parseInt(document.getElementById('upTodayInput')?.value)]);


  const handlePredefinedRange = (range) => {
    // alert(range)
    const currentDate = new Date();
    let newStartDate = new Date();
    let newEndDate = new Date();

    switch (range) {
      case 'today':
        newStartDate = currentDate;
        newEndDate = currentDate;
        break;
      case 'yesterday':
        newStartDate = new Date(currentDate);
        newStartDate.setDate(currentDate.getDate() - 1);
        newEndDate = newStartDate;
        break;
      case 'week':
        newStartDate = new Date(currentDate);
        newStartDate.setDate(currentDate.getDate() - currentDate.getDay());
        newEndDate = currentDate;
        break;
      case 'last-days':
        const lastDays = parseInt(document.getElementById('lastDaysInput')?.value);
        handleSelectByDays(lastDays)
        break;

      case 'month':
        newStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        newEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        break;
      case 'lastMonth':
        newStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
        newEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
        break;
      case 'thisYear':
        // newStartDate = new Date(currentDate.getFullYear(), 0, 1);
        // newEndDate = new Date(currentDate.getFullYear(), 11, 31);
        newStartDate = null;
        newEndDate = null
        setYearFilter(true)
        break;
      case 'upToday':
        const upTodayDays = parseInt(document.getElementById('upTodayInput').value);
        handleSelectByDays(upTodayDays)
        break;
      case 'reset':
        newStartDate = null;
        newEndDate = null
        setYearFilter(false)
      // Add more cases as needed
      default:
        break;
    }
    // setDateRange([{ startDate, endDate, key: 'selection' }]);
    // setSelectedRange(range);
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const handleDateSelect = (selectedDate) => {
    if (selectingStartDate) {
      setStartDate(selectedDate);
    } else {
      setEndDate(selectedDate);
    }
    setSelectingStartDate(!selectingStartDate); // Toggle selectingStartDate flag
  };

  return (
    <div className="posting-behaviour-tab">
      <div className="chart-section">
        <div className="section-title">
          <strong className="white large">Time posting patterns</strong>
        </div>
        <div className="section-heading">
          <div className="container-fluid">
            <div className="row">
              <div className="align-self-center col-lg-6 col-md-6 col-xl-7">
                <div className="titles mb-4 mb-md-0">
                  <strong className="white d-block mb-2">Time Posting Patterns and Engagement Metrics</strong>
                  <span className='d-block'>Analyzing User Engagement in Relation to Post Time</span>
                </div>
              </div>
              <div className="align-self-center col-lg-6 col-md-6 col-xl-5">
                <div className="date-wrapper">
                  {analyticsDataLoading30d ? <span
                    className='white d-inline-flex gap-2'>  <Spinner
                      animation="grow" size="sm" /><span
                        className=' d-inline-block white'>Analyzing...</span></span> : <ul className="d-list">
                    <li
                      onClick={() =>
                        setDaysFilter((pre) => pre == 7 ? null : (7))
                      }
                      className={`${daysFilter == 7 ? "white" : ""}`}
                    >
                      <span>7D</span>
                    </li>
                    <li
                      onClick={() =>
                        setDaysFilter((pre) => pre == 30 ? null : (30))
                      }
                      className={`${daysFilter == 30 ? "white" : ""}`}
                    >
                      <span>30D</span>
                    </li>
                    {/* <li
                      onClick={() =>
                        setDaysFilter((pre) => (null))
                      }
                      className={`${daysFilter == null ? "white" : ""}`}
                    >
                      <span>All</span>
                    </li> */}
                  </ul>}
                  {endDate && analyticsDataLoading && <span
                    className='white d-inline-flex gap-2'>  <Spinner
                      animation="grow" size="sm" /><span
                        className=' d-inline-block white'>Analyzing...</span></span>}
                  <div onClick={handleDateRange} className="d-flex gap-2 align-items-center " style={{ cursor: 'pointer' }}>
                    <div
                      className={`box-icon ${dateRange ? "active" : ""}`}
                    >
                      <IoCalendarClear />
                    </div>
                    <p className="white">Set date range</p>
                  </div>
                </div>
                <div
                  ref={modalRef}
                  className={`select-time-main-wrapper chart ${dateRange ? "active" : ""
                    }`}
                >
                  <form action="#">
                    <ul className="time" onChange={(e) => handlePredefinedRange(e.target.value)}>
                      <li>
                        <input type="radio" id="Today" name="time" value="today" />
                        <label htmlFor="Today">Today</label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          id="Yesterday"
                          name="time"
                          value="yesterday"
                        />
                        <label htmlFor="Yesterday">Yesterday</label>
                      </li>
                      <li>
                        <input type="radio" id="week" name="time" value="week" />
                        <label htmlFor="week">This Week</label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          id="last-days"
                          name="time"
                          value="last-days"
                        />
                        <label htmlFor="last-days">
                          Last <input type="text" id="lastDaysInput" /> Days
                        </label>
                      </li>

                      <li>
                        <input type="radio" id="month" name="time" value="month" />
                        <label htmlFor="month">
                          This Month{" "}
                          {`(${new Date().toLocaleString("en-US", {
                            month: "long",
                            timeZone: "UTC",
                          })})`}
                        </label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          id="lastMonth"
                          name="time"
                          value="lastMonth"
                        />
                        <label htmlFor="lastMonth">
                          Last Month
                          {/*{`(${getLastMonthName()})`}*/}
                        </label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          id="thisYear"
                          name="time"
                          value="thisYear"
                        />
                        <label htmlFor="thisYear">
                          This Year {`(${new Date().getFullYear()})`}
                        </label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          id="upToday"
                          name="time"
                          value="upToday"
                        />
                        <label htmlFor="upToday">
                          <input type="text" id="upTodayInput" /> Days up to today
                        </label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          id="reset"
                          name="time"
                          value="reset"
                        />
                        <label htmlFor="reset">
                          Reset
                        </label>
                      </li>
                    </ul>
                  </form>
                  <div className="calendar-wrapper">
                    <div className="select-time">
                      <div className="select">
                        <span className="white">
                          {startDate ? startDate.toLocaleDateString() : ' '}
                        </span>
                      </div>
                      <span className="white mx-3">-</span>
                      <div className="select">
                        <span className="white">
                          {endDate ? endDate.toLocaleDateString() : ' '}
                        </span>
                      </div>
                    </div>
                    <div className="calandar">
                      {/* <CalendarComponent /> */}
                      <Calendar onChange={handleDateSelect} value={selectingStartDate ? startDate : endDate} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="chart-content-wrapper">
          <div className="row">
            <div className="col-lg-4 col-xxl-2  col-md-4 align-self-center">
              <ul className="lists">
                <li>
                  <button className={`${smallChartTabs == 'engagement-behavior' ? 'active' : ''}`} onClick={() => setSmallChartTabs('engagement-behavior')}><TbWaveSawTool style={{ color: '#0dabea' }} /> Engagements</button>
                </li>
                <li>
                  <button className={`${smallChartTabs == 'videos-behavior' ? 'active' : ''}`} onClick={() => setSmallChartTabs('videos-behavior')}><PiVideoCameraFill style={{ color: '#FF165D' }} /> Videos</button>
                </li>
                <li>
                  <button className={`${smallChartTabs == 'views-behavior' ? 'active' : ''}`} onClick={() => setSmallChartTabs('views-behavior')}><BsFillEyeFill
                    style={{ color: '#00F2EA' }} /> Views
                  </button>
                </li>
                {/* <li> <button className={`${smallChartTabs == 'followers-behavior' ? 'active' : ''}`} onClick={() => setSmallChartTabs('followers-behavior')}><FaUser style={{color: '#FF6400'}}/> Followers</button> </li> */}
                <li>
                  <button className={`${smallChartTabs == 'comments-behavior' ? 'active' : ''}`} onClick={() => setSmallChartTabs('comments-behavior')}><img src={smsPurpleIcon}
                    alt="chartsBlackIcon" /> Comment
                  </button>
                </li>
                <li>
                  <button className={`${smallChartTabs == 'shares-behavior' ? 'active' : ''}`} onClick={() => setSmallChartTabs('shares-behavior')}><PiShareFatFill
                    style={{ color: '#ff6400' }} /> Shares
                  </button>
                </li>
                <li>
                  <button className={`${smallChartTabs == 'bookmarks-behavior' ? 'active' : ''}`} onClick={() => setSmallChartTabs('bookmarks-behavior')}><IoBookmark style={{ color: '#34A853' }} /> Bookmarks
                  </button>
                </li>
                <li>
                  <button className={`${smallChartTabs == 'likesx`-behavior' ? 'active' : ''}`} onClick={() => setSmallChartTabs('likes-behavior')}><FcLike style={{ color: '##5352B0' }} /> Likes
                  </button>
                </li>
                {/* <li> <button><IoGrid style={{color: 'white'}}/> All</button> </li> */}
              </ul>
            </div>
            <div className="col-lg-8 col-xxl-10 col-md-8 align-self-center">
              <div className="graph-wrapper">
                {/* <div className="graph-title">
                  <div className="dot" style={{background: '#0DABEA'}}></div>
                  <span>Engagement</span></div> */}
                <GreenLargeChart
                  data={chartData}
                  smallChartTabs={smallChartTabs}
                  analyticsData={analyticsData}
                  chartTabs={chartTabs}
                  colors={smallChartTabs == 'comments-behavior' ? { gradient: 'rgba(42, 46, 86, 1)', borderColor: 'rgb(83, 82, 176' } : smallChartTabs == 'views-behavior' ? { gradient: 'rgba(25, 47, 62, 1)', borderColor: 'rgb(0, 242, 234)' } : smallChartTabs == 'videos-behavior' ? { gradient: 'rgba(66, 30, 56, 1)', borderColor: 'rgba(255, 22, 93, 1)' } : smallChartTabs == 'shares-behavior' ? { gradient: 'rgba(33, 73, 58, 1)', borderColor: 'rgb(52, 168, 83)' } : smallChartTabs == 'bookmarks-behavior' ? { gradient: 'rgba(33, 73, 58, 1)', borderColor: 'rgb(52, 168, 83)' } : smallChartTabs == 'likesx`-behavior' ? { gradient: 'rgba(66, 30, 56, 1)', borderColor: 'rgba(255, 22, 93, 1)' } : smallChartTabs == 'followers-behavior' ? { gradient: 'rgba(42, 46, 86, 1)', borderColor: 'rgb(83, 82, 176)' } : { gradient: 'rgba(66, 30, 56, 1)', borderColor: 'rgba(255, 22, 93, 1)' }}
                  chartLabels={chartLabels}
                />
                {/* <DoubleLineChart /> */}
              </div>
            </div>
          </div>
          {/*<div className="chart-tabs-wrapper">
            <div
                className={`chart-tab ${
                    smallChartTabs == "engagement-behavior" ? "active" : ""
                }`}
                onClick={() => smallChartTabsHandler("engagement-behavior")}
            >
              <div className="img-wrapper">
                <img src={pinkChartSmallImg} alt="cyanChartSmallImg"/>
              </div>
              <div className="text">
                <p>Engagement</p>
              </div>
            </div>
            <div
                className={`chart-tab ${
                    smallChartTabs == "count-behavior" ? "active" : ""
                }`}
                onClick={() => smallChartTabsHandler("count-behavior")}
            >
              <div className="img-wrapper">
                <img src={cyanChartSmallImg} alt="cyanChartSmallImg"/>
              </div>
              <div className="text">
                <p>Post count</p>
              </div>
            </div>
            <div
                className={`chart-tab ${
                    smallChartTabs == "views-behavior" ? "active" : ""
                }`}
                onClick={() => smallChartTabsHandler("views-behavior")}
            >
              <div className="img-wrapper">
                <img src={orangeChartSmallImg} alt="cyanChartSmallImg"/>
              </div>
              <div className="text">
                <p>Views</p>
              </div>
            </div>
            <div
                className={`chart-tab ${
                    smallChartTabs == "likes-behavior" ? "active" : ""
                }`}
                onClick={() => smallChartTabsHandler("likes-behavior")}
            >
              <div className="img-wrapper">
                <img src={purpleChartSmallImg} alt="cyanChartSmallImg"/>
              </div>
              <div className="text">
                <p>Likes</p>
              </div>
            </div>
            <div
                className={`chart-tab ${
                    smallChartTabs == "comments-behavior" ? "active" : ""
                }`}
                onClick={() => smallChartTabsHandler("comments-behavior")}
            >
              <div className="img-wrapper">
                <img src={greenChartSmallImg} alt="cyanChartSmallImg"/>
              </div>
              <div className="text">
                <p>Comments</p>
              </div>
            </div>
            <div
                className={`chart-tab ${
                    smallChartTabs == "shares-behavior" ? "active" : ""
                }`}
                onClick={() => smallChartTabsHandler("shares-behavior")}
            >
              <div className="img-wrapper">
                <img src={yellowChartSmallImg} alt="cyanChartSmallImg"/>
              </div>
              <div className="text">
                <p>Shares</p>
              </div>
            </div>
            <div
                className={`chart-tab ${
                    smallChartTabs == "bookmarks-behavior" ? "active" : ""
                }`}
                onClick={() => smallChartTabsHandler("bookmarks-behavior")}
            >
              <div className="img-wrapper">
                <img src={yellowChartSmallImg} alt="cyanChartSmallImg"/>
              </div>
              <div className="text">
                <p>Bookmarks</p>
              </div>
            </div>
          </div>*/}
        </div>
        <div className="average-text blue">
          <p className="small">
            @{profileData?.username}’s engagements peak at times he posted by
            8PM
          </p>
        </div>
      </div>
    </div>

  );
};

export default TimePostingPatternsMain;
