// // BarChart.js
// import React from 'react';
// import { Bar } from 'react-chartjs-2';
// import 'chart.js/auto';
// import ChartDataLabels from 'chartjs-plugin-datalabels';

// const BarChart = () => {
//     const data = {
//         labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
//         datasets: [
//             {
//                 label: 'Time Spent',
//                 data: [25, 1000, 400, 90, 56, 200, 300],
//                 backgroundColor: '#FF4081',
//                 borderRadius: 10,
//                 barThickness: 'flex',
//                 maxBarThickness: 15, // Adjusted value
//             },
//         ],
//     };

//     const options = {
//         // maintainAspectRatio: false,
//         indexAxis: 'y',
//         maintainAspectRatio: true,
//         aspectRatio: 0,
//         scales: {
//             x: {
//                 ticks: {
//                     callback: (value) => `${value}s`,
//                     color: '#646F7C',
//                     display: false,
//                 },
//                 font: {
//                     size: 5, // Font size for x-axis labels
//                 },
//                 grid: {
//                     display: false,
//                 },
//             },
//             y: {
//                 beginAtZero: true,
//                 ticks: {
//                     color: '#646F7C',
//                     // align: 'left',
//                     crossAlign: "far",
//                     font: {
//                         size: 12, // Font size for x-axis labels
//                     },
//                 },
//                 grid: {
//                     display: false,
//                 },
//             },
//         },
//         plugins: {
//             legend: {
//                 display: false,
//             },
//             tooltip: {
//                 callbacks: {
//                     label: (context) => `${context.raw}s`,
//                 },
//             },
//             datalabels: {
//                 align: 'end',
//                 anchor: 'end',
//                 color: 'white',
//                 clip: true, // Ensuring labels stay within chart area
//                 formatter: (value) => `${value}s`,
//                 padding: {
//                     // right: 10 // Adding some padding to avoid overlap
//                 },
//                 font: {
//                     size: 10, // Font size for data labels
//                 },
//             },
//         },
//         layout: {
//             padding: {
//                 right: 30, // Adding padding to ensure enough space for the labels

//             }
//         },
//     };

//     return (
//         <div style={{ position: 'relative', height: '250px', width: '100%', padding:'20px 0' }}>
//             <Bar data={data} options={options} plugins={[ChartDataLabels]} />
//         </div>
//     );
// };

// export default BarChart;


import React from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const BarChart = ({ durationData, color }) => {
    // Formatter function to format numbers with "m" suffix
    const formatter = function(value, context) {
        if (typeof value === 'number') {
            if (value >= 1000000000) {
                return (value / 1000000000).toFixed(1) + 'b'; // Format as billion with one decimal place
            } else if (value >= 1000000) {
                return (value / 1000000).toFixed(1) + 'm'; // Format as million with one decimal place
            } else if (value >= 1000) {
                return (value / 1000).toFixed(1) + 'k'; // Format as thousand with one decimal place
            } else {
                return value.toString(); // Return as-is if less than 1 thousand
            }
        }
        return value.toString(); // Return as-is if not a number
    };
    

    // Create chart data object
    const data = {
        labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        datasets: [
            {
                label: 'Time Spent',
                data: durationData, // Use original numeric data here
                backgroundColor: color || '#FF4081', // Use provided color or default
                borderRadius: 10,
                barThickness: 'flex',
                maxBarThickness: 15,
            },
        ],
    };

    // Chart options configuration
    const options = {
        indexAxis: 'y',
        maintainAspectRatio: false,
        scales: {
            x: {
                ticks: {
                    display: false,
                    font: {
                        size: .5,
                    },
                },
                // Adjust scale settings as needed
                grid: {
                    display: false,
                },
            },
            y: {
                beginAtZero: true,
                ticks: {
                    color: '#646F7C',
                    crossAlign: 'far',
                    font: {
                        size: 12,
                    },
                },
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                // enabled: false,
                callbacks: {
                    // Custom tooltip label formatting if needed
                    label: (context) => `${context.raw}`,
                },
            },
            datalabels: {
                // Customize data labels for exact display
                formatter: formatter, // Use formatter function defined above
                color: 'white',
                align: 'end',
                anchor: 'end',
                clip: false,
                font: {
                    size: 10,
                },
                padding: {
                    right: 10,
                },
            },
        },
        layout: {
            padding: {
                right: 30,
                left: 10,
                top: 20,
                bottom: 20,
            },
        },
    };

    // Return JSX for BarChart component
    return (
        <div style={{ position: 'relative', height: '255px', width: '100%', padding: '10px 0' }}>
            <Bar data={data} options={options} plugins={[ChartDataLabels]} />
        </div>
    );
};

// Export BarChart component as default
export default BarChart;
