// import React, { useEffect, useRef, useState } from 'react';
// import Chart from 'chart.js/auto'; // Import Chart object

// export default function LineChartBookmarks({ analyticsData }) {
//     const [commentsTimeSeries, setCommentsTimeSeries] = useState([]);
//     const canvasRef = useRef(null);
//     useEffect(() => {

//         if (!analyticsData) return
//         var commentsTimeSeries = (analyticsData?.bookmarks_timeseries || [])
//         //if commentsTimeSeries length is bigger than 100, calculate the total views  for each month, instad hcing the data for each day 
//         if (commentsTimeSeries?.length > 100) {
    
//           var commentsTimeSeriesByMonth = {}
//           commentsTimeSeries.forEach((comments) => {
    
//             var date = new Date(comments['Date Posted'])
//             var month = date.getMonth()
//             //get month word 
//             month = new Date(date.getFullYear(), month, 1).toLocaleString('default', { month: 'short' })
//             var year = date.getFullYear()
//             var monthYear = month + '-' + year
//             if (commentsTimeSeriesByMonth[monthYear]) {
//               commentsTimeSeriesByMonth[monthYear].Bookmarks += comments.Bookmarks
//             } else {
//               commentsTimeSeriesByMonth[monthYear] = {
//                 Bookmarks: comments.Bookmarks,
//                 'Date Posted': monthYear
//               }
//             }
//           }
//           )
    
//           commentsTimeSeries = Object.values(commentsTimeSeriesByMonth)
//         }
//         setCommentsTimeSeries(commentsTimeSeries)
    
    
    
//       }, [analyticsData])


//     useEffect(() => {
//         // Create gradient
//         const canvas = canvasRef.current;
//         const ctx = canvas.getContext('2d');
//         const gradient = ctx.createLinearGradient(0, 0, 0, '400'); // Adjust according to your chart size
//         gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
//         gradient.addColorStop(0, 'rgba(66, 43, 39, 1)'); // A djust as needed

//         // Apply gradient as background color
//         const chartInstance = new Chart(ctx, {
//             type: 'line',
//             data: {
//                 labels: commentsTimeSeries.map((share) => share['Date Posted']),
//                 datasets: [
//                     {
//                         label: 'Dataset',
//                         backgroundColor: gradient,
//                         borderColor: '#FF6400',
//                         borderWidth: 2,
//                         fill: true,
//                         pointBackgroundColor: 'rgba(0, 0, 0, 0)',
//                         pointBorderColor: 'rgba(0, 0, 0, 0)',
//                         pointHoverBackgroundColor: '#fff',
//                         pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
//                         data: commentsTimeSeries?.map((comment) => (comment?.Bookmarks?.toFixed(0))),
//                         lineTension: 0.4, // Adjust the tension value for smoothness
//                         zIndex: 1,
//                     }
//                 ]
//             },
//             options: {
//                 plugins: {
//                     legend: {
//                         display: false,
//                     }
//                 },
//                 scales: {
//                     // yAxes: [{
//                     //     ticks: {
//                     //         beginAtZero: true
//                     //     }
//                     // }],
//                     // xAxes: [{
//                     //     gridLines: {
//                     //         z: 99
//                     //     }
//                     // }],
//                     x: {
//                         // ticks: {
//                         //     display: false // Hide the ticks (labels) on the x-axis
//                         // },
//                         grid: {
//                             display: false,
//                             z: 99
//                         }
//                     },
//                     y: {
//                         ticks: {
//                             beginAtZero: true
//                         },
//                         grid: {
//                             display: true,
//                             color: 'rgba(100, 111, 124, .2)',
//                             zIndex: 9999,
//                             drawOnChartArea: true,
//                         },
//                     }
//                 },

//             },
//         });

//         return () => {
//             chartInstance.destroy(); // Cleanup chart instance when component unmounts
//         };
//     }, [analyticsData, commentsTimeSeries]);

//     return (
//         <div className='graph-chart-wrapper'>
//             <canvas ref={canvasRef} />
//         </div>
//     );
// }




import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto'; // Import Chart object
import ShortenNumber from '../utilities/ShortenNumber';
import moment from 'moment';

export default function LineChartBookmarks({ analyticsData }) {
    const [bookmarksTimeSeries, setBookmarksTimeSeries] = useState([]);
    const canvasRef = useRef(null);

    // useEffect(() => {
    //     if (!analyticsData) return;

    //     var bookmarksTimeSeries = analyticsData?.bookmarks_timeseries || [];
    //     var bookmarksTimeSeriesByYear = {};

    //     bookmarksTimeSeries?.forEach((bookmark) => {
    //         var date = new Date(bookmark['Date Posted']);
    //         var year = date.getFullYear();

    //         if (bookmarksTimeSeriesByYear[year]) {
    //             bookmarksTimeSeriesByYear[year].totalBookmarks += bookmark.Bookmarks;
    //             bookmarksTimeSeriesByYear[year].count += 1;
    //         } else {
    //             bookmarksTimeSeriesByYear[year] = {
    //                 totalBookmarks: bookmark.Bookmarks,
    //                 count: 1
    //             };
    //         }
    //     });

    //     var averagedBookmarksTimeSeries = Object.entries(bookmarksTimeSeriesByYear).map(([year, data]) => ({
    //         year: year,
    //         averageBookmarks: (data.totalBookmarks / data.count).toFixed(0)
    //     }));

    //     setBookmarksTimeSeries(averagedBookmarksTimeSeries);

    // }, [analyticsData]);


    useEffect(() => {
        if (!analyticsData) return;
    
        const bookmarksTimeSeries = analyticsData?.bookmarks_timeseries || [];
        const bookmarksTimeSeriesByMonth = {};
    
        bookmarksTimeSeries?.forEach((bookmark) => {
            const date = new Date(bookmark['Date Posted']);
            const year = date.getFullYear();
            const month = date.getMonth() + 1; // getMonth() returns 0-11, so add 1 to make it 1-12
            const yearMonth = moment(date).format('MM/YY'); // Format as YYYY-MM
    
            if (bookmarksTimeSeriesByMonth[yearMonth]) {
                bookmarksTimeSeriesByMonth[yearMonth].totalBookmarks += bookmark.Bookmarks;
                bookmarksTimeSeriesByMonth[yearMonth].count += 1;
            } else {
                bookmarksTimeSeriesByMonth[yearMonth] = {
                    totalBookmarks: bookmark.Bookmarks,
                    count: 1
                };
            }
        });
    
        const averagedBookmarksTimeSeries = Object.entries(bookmarksTimeSeriesByMonth).map(([yearMonth, data]) => ({
            year: yearMonth,
            averageBookmarks: (data.totalBookmarks / data.count).toFixed(0)
        }));
    
        setBookmarksTimeSeries(averagedBookmarksTimeSeries);
    
    }, [analyticsData]);
    
    useEffect(() => {
        // Create gradient
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 400); // Adjust according to your chart size
        gradient.addColorStop(1, 'rgba(25, 31, 47, 1)');
        gradient.addColorStop(0, 'rgba(0, 242, 234, 0)'); // Adjust as needed
        // rgba(66, 43, 39, 1)

        const chartInstance = new Chart(ctx, {
            type: 'line',
            data: {
                labels: bookmarksTimeSeries?.map((data) => data.year),
                datasets: [
                    {
                        label: 'Average Bookmarks per Year',
                        backgroundColor: gradient,
                        borderColor: 'rgba(0, 242, 234, 1)',
                        borderWidth: 2,
                        fill: true,
                        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                        pointBorderColor: 'rgba(0, 0, 0, 0)',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
                        data: bookmarksTimeSeries.map((data) => data.averageBookmarks),
                        lineTension: 0.4, // Adjust the tension value for smoothness
                    }
                ]
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                scales: {
                    x: {
                        ticks : {
                            color:'#D9D9D9',
                        },
                        grid: {
                            display: false,
                        },
                    },
                    y: {
                        ticks: {
                            color:'#D9D9D9',
                            beginAtZero: true,
                            callback: function (value) {
                                return ShortenNumber(value);
                            },
                            maxTicksLimit:5
                        },
                        grid: {
                            display: true,
                            color: 'rgba(100, 111, 124, .2)',
                        },
                    }
                },
            },
        });

        return () => {
            chartInstance.destroy(); // Cleanup chart instance when component unmounts
        };
    }, [bookmarksTimeSeries]);

    return (
        <div className='graph-chart-wrapper'>
            <canvas ref={canvasRef} />
        </div>
    );
}
