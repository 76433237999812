import React, { Suspense, useEffect, lazy, useCallback } from 'react';
import './App.scss';

// ********************* Dependencies *********************

import { BrowserRouter as Router, Routes, Route, HashRouter, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { basePath } from './services/constents';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ApiRequests } from './services/apiRequests';
import { useDispatch } from 'react-redux';
import { authenticateAsyncThunk } from './store/authSlice';
import LoadingComponent from "./components/LoadingComponent";

//********************** Pages  ***************************

// import SignupPage from './pages/SignupPage';
// import SigninPage from './pages/SigninPage';
// import ForgotPassword from './pages/ForgotPassword';
// import ProfileRating from "./pages/ProfileRating";
// import MyProfiles from "./pages/MyProfiles";
// import SettingsPage from "./pages/SettingsPage";
// import MyDownloadsPage from "./pages/MyDownloadsPage";
// import MyDownloadsPage2 from "./pages/MyDownloadsPage2";
// import ScanNewProfile from "./pages/ScanNewProfile";
// import Test from "./pages/Test";
// import Pricing from './pages/Pricing';
// import Authenticate from './pages/Authenticate';
// import ResetPassword from "./pages/ResetPassword";
// import DownloadImages from "./pages/DownloadImages";
// const ScanProfile = lazy(() => import("./pages/ScanProfile"));
import Pricing from './pages/Pricing';
import ScanNewProfile from "./pages/ScanNewProfile";

const SignupPage = lazy(() => import('./pages/SignupPage'));
const SigninPage = lazy(() => import('./pages/SigninPage'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const ProfileRating = lazy(() => import('./pages/ProfileRating'));
const MyProfiles = lazy(() => import('./pages/MyProfiles'));
const SettingsPage = lazy(() => import('./pages/SettingsPage'));
const MyDownloadsPage = lazy(() => import('./pages/MyDownloadsPage'));
const MyDownloadsPage2 = lazy(() => import('./pages/MyDownloadsPage2'));
const Test = lazy(() => import('./pages/Test'));
const Authenticate = lazy(() => import('./pages/Authenticate'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const DownloadImages = lazy(() => import('./pages/DownloadImages'));
const ScanProfile = lazy(() => import('./pages/ScanProfile'));

const userLocalStorage = localStorage.getItem("user");
let user;

if (userLocalStorage) {
  try {
    user = JSON.parse(userLocalStorage);
  } catch (error) {
    console.error("Parsing error:", error);
    // Handle the error, maybe set user to a default value
    user = null; // or some default object
  }
} else {
  // If there's nothing in local storage, set user to null or a default object
  user = null; // or some default object
}


function App() {
  console.log('server path ', basePath)
  const accessToken = localStorage.getItem('app-access-token');
  const dispatch = useDispatch()

  // useEffect(() => {
  //   async function fetchCurrentUser() {
  //     try {
  //     const user = await ApiRequests.getCurrentUser()
  //     console.log('current user ',user)
  //     } catch (error) {
  //       console.log('error fetching current user ', error)
  //     }
  //   }
  //   fetchCurrentUser()
  const authenticate = useCallback(() => {
    const token = localStorage.getItem("app-access-token");
    if (token) {
      dispatch(authenticateAsyncThunk());
    }
  }, [dispatch]);

  // useEffect(() => {
  //   const token = localStorage.getItem("app-access-token");
  //   dispatch(authenticateAsyncThunk());
  // }, [dispatch]);

  useEffect(() => {
    if (window.location.pathname !== '/sign-in') {
      authenticate();
    }
  }, [authenticate]);


  // },[])
  // useEffect(() => {
  //   const appRefresh = localStorage.getItem("app-refresh");
  //   async function fetchCurrentUser() {
  //     try {
  //     const resp = await ApiRequests.getCurrentUser()
  //     console.log('current user ',resp)
  //     if(resp?.status == 200){

  //       // setUser(user.data)
  //       const {user, tokens} = resp?.data?.data
  //       console.log('user is ', user)
  //       localStorage.setItem(
  //         "app-access-token",
  //         tokens?.access.token
  //       );
  //       localStorage.setItem(
  //         "app-access",
  //         JSON.stringify(tokens?.access)
  //       );
  //       localStorage.setItem(
  //         "app-refresh",
  //         JSON.stringify(tokens?.refresh)
  //       );
  //       localStorage.setItem(
  //         "app-refresh-token",
  //         tokens?.refresh?.token
  //       );
  //       localStorage.setItem("user", JSON.stringify(user));
  //       localStorage.setItem("isLoggedIn", JSON.stringify(true));
  //     }
  //     } catch (error) {
  //       console.log('error fetching current user ', error)
  //     }
  //   }

  //   fetchCurrentUser()
  // },[])

  useEffect(() => {
    // Intercom Settings Script
    window.intercomSettings = user ? {
      api_base: "https://api-iam.intercom.io",
      app_id: "ikgd1nr5",
      name: user?.name, // Replace with the actual name from your React component state or props
      email: user?.email, // Replace with the actual email from your React component state or props
      created_at: user?.createdAt, // Replace with the actual signup date
    } : {
      api_base: "https://api-iam.intercom.io",
      app_id: "ikgd1nr5"
    };

    // Intercom Widget Script
    (function () {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === "function") {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        var d = document;
        var i = function () { i.c(arguments); };
        i.q = [];
        i.c = function (args) { i.q.push(args); };
        w.Intercom = i;
        var l = function () {
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/ikgd1nr5';
          var x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  }, []);

  return (
    <div className="App">
      <Router>
        {/* <Router> */}
        <Suspense
          fallback={<div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
              zIndex: "10",
            }}
            className="loadingio-spinner-interwind-flyom1cz6sv"
          >
            <div className="ldio-zxrz71mlja">
              <div>
                <div>
                  <div>
                    <div></div>
                  </div>
                </div>
                <div>
                  <div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>}
        >
          <Routes>
            <Route exact path="/signup" element={<SignupPage />} />
            <Route exact path="/sign-in" element={<SigninPage />} />
            <Route exact path="/reset-password" element={<ResetPassword />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/" element={<ScanProfile isEmbedded={true} />} />
            <Route exact path="/profile-rating" element={<ProfileRating />} />
            <Route exact path="/my-profiles" element={<MyProfiles />} />
            <Route exact path="/setting" element={<SettingsPage />} />
            <Route exact path="/downloads2" element={<MyDownloadsPage />} />
            <Route exact path="/downloads" element={<MyDownloadsPage2 />} />
            <Route exact path="/scan-profile-new" element={<ScanNewProfile />} />
            <Route exact path="/test" element={<Test />} />
            <Route exact path="/pricing" element={<Pricing />} />
            <Route exact path="/authenticate" element={<Authenticate />} />
            <Route exact path="/download-images" element={<DownloadImages />} />
          </Routes>
        </Suspense>
        {/* </Router> */}
      </Router>
    </div>
  );
}

export default App;
